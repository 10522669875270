import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FieldValues, useForm } from "react-hook-form"
import { Button, Col, Form, Row } from "reactstrap"

import ButtonWithLoader from "../../../ButtonWithLoader/ButtonWithLoader.component"
import DatePickerWrapper from "../../../forms/inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../../forms/inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../../forms/inputFields/Select/SelectWrapper.component"
import { setDefaultFiltersValues, setQueryString } from "./utils"

type ContractsFiltersProps = {
  selectedFilters: string
  setFilters: (filters: string) => void
  setPage: (page: number) => void
  fieldsValues: FieldValues
  refetch: Function
}

const ContractsFilters = ({
  selectedFilters,
  setFilters,
  fieldsValues,
  refetch,
  setPage,
}: ContractsFiltersProps) => {
  const { register, control, reset, handleSubmit, formState: { isSubmitting } } = useForm()

  const onSubmit = async (values: any) => {
    const queryParams = setQueryString(values)
    const filters = queryParams.length ? queryParams.join('&') : ""
    //If selected filters are not equal to the former ones
    //reset selected page to 1 and set filters with new values
    if(selectedFilters !== filters) {
      setFilters(filters)
      setPage(1)
    }
    //Otherwise, refetch with the same query
    else {
      refetch()
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="grouped filters-container">
        <div className="grouped-header">
          <FontAwesomeIcon icon="filter" className="me-2" />
          Applica filtri
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={3}>
              <SelectWrapper 
                name="statusId"
                label="Stato contratto"
                control={control}
                options={fieldsValues?.statusId ?? []}
              />
            </Col>
            <Col md={3}>
              <InputWrapper 
                register={register}
                name="employeeName"
                type="text"
                className="form-control"
                label="Nome"
              />
            </Col>
            <Col md={3}>
              <InputWrapper 
                register={register}
                name="employeeFiscalCode"
                type="text"
                className="form-control text-uppercase"
                label="Codice fiscale"
              />
            </Col>
            <Col md={3}>
              <InputWrapper 
                register={register}
                name="ccnlFraming"
                type="text"
                className="form-control"
                label="Ccnl"
              />
            </Col>
            <Col md={3}>
              <InputWrapper 
                register={register}
                name="role"
                type="text"
                className="form-control"
                label="Qualifica"
              />
            </Col>
            <Col md={3}>
              <DatePickerWrapper
                name="insertDateStart"
                control={control}
                label="Data caricamento da"
                className="form-control"
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col md={3}>
              <DatePickerWrapper
                name="insertDateEnd"
                control={control}
                label="Data caricamento a"
                className="form-control"
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col md={3}>
              <DatePickerWrapper
                name="signingDateStart"
                control={control}
                label="Data firma da"
                className="form-control"
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col md={3}>
              <DatePickerWrapper
                name="signingDateEnd"
                control={control}
                label="Data firma a"
                className="form-control"
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
              />
            </Col>
          </Row>
          <div className="text-end my-3">
            <Button
              className="me-1"
              outline
              size="lg"
              onClick={() => reset(setDefaultFiltersValues())}
            >
              <FontAwesomeIcon icon="undo" className="me-2" />
              Reset
            </Button>
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              size="lg"
              disabled={isSubmitting}
              label="Filtra"
              fontAwesomeIcon={["fas", "filter"]}
            />
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ContractsFilters