import { ContractStatusMap, ContractTabsMap } from "../../../../redux/contract/contract.utils"

export function isTabDisabled(tabNumber: number, formStep: number) {
  if(tabNumber > formStep) return true
  return false
}

//If the contract status is less than DONE_STATUS do not show the attachments tab
//If the contract entity name is audaxChangeSupplier always show it
export function attachmentsTabVisibility(entityName: string, selectedContractStatusId: number) {
  if(entityName === 'audaxChangeSupplier') {
    return true
  }

  return selectedContractStatusId >= ContractStatusMap.DONE_STATUS
}

//Show the alert when at least hasPod or hasPdr step is TRUE.
type IsSignContractAlertType = {
  entityName?: string
  maxFormStepAvailable: number
  selectedContractStatusId: number
}
export function isSignContractAlertVisible({
  entityName,
  maxFormStepAvailable,
  selectedContractStatusId,
}: IsSignContractAlertType) {  
  return (
    maxFormStepAvailable >= ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP && 
    selectedContractStatusId === ContractStatusMap.ONGOING_STATUS
  ) ? true : false
}