export function isUseTypeNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}

export function isGasExciseClassIdNeeded(entityName: string, values: any) {
  if(entityName === 'changeSupplier') {
    return true
  }

  return (
    (
      entityName === 'transfer' ||
      entityName === 'contextualTransferGas' ||
      entityName === 'takeover' ||
      entityName === 'newActivation' ||
      entityName === 'newConnection' ||
      entityName === 'audaxChangeSupplier'
    ) && (
      values.contractType &&
      parseInt(values.contractType.value) !== 1 &&
      parseInt(values.contractType.value) !== 2 &&
      parseInt(values.contractType.value) !== 3
    )
  ) ? true : false
}

export function isResidentNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}