import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { CustomerTypes, getCustomerType } from "../../utils/utils"
import { isFAttachmentRequired } from "./utils"

const FAttachment = ({
  customerType,
  canEdit,
  fieldsValues: pdrFieldsValues
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  return (
    <ShowOnCondition
      showWhen={
        isFAttachmentRequired(entityName) &&
        parseInt(formWatcher.vatClassId?.value) === 2 &&
        (
          getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType!) ||
          (
            getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType!) &&
            formWatcher.gasUseTypeId &&
            parseInt(formWatcher.gasUseTypeId?.value) !== 1
          )
        )
      }
    >
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="list" className="me-2" />
          Allegato F
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <SelectWrapper
                name="scope"
                control={control}
                label="Finalità uso gas"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={pdrFieldsValues?.scope || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <ShowOnCondition showWhen={parseInt(formWatcher.scope?.value) === 1}>
              <Col md={6}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="atecoCodeCompany"
                  label="Codice Ateco"
                  rules={{ 
                    maxLength: 8,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <SelectWrapper
                  name="atecoCategoryId"
                  control={control}
                  label="Categoria codice Ateco"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={pdrFieldsValues?.atecoCategoryId || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <ShowOnCondition showWhen={parseInt(formWatcher.atecoCategoryId?.value) === 5}>
                <Col md={12}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="otherText"
                    label="Altro (specificare)"
                    rules={{ 
                      maxLength: 128,
                      required: errorFormLabels.REQUIRED
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
              </ShowOnCondition>
            </ShowOnCondition>
          </Row>
          <Row>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="otherGroup"
                label="Gruppo"
                rules={{ maxLength: 128 }}
                register={register}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="otherSpecies"
                label="Specie"
                rules={{ maxLength: 128 }}
                register={register}
              />
            </Col>
          </Row>
          <ShowOnCondition showWhen={parseInt(formWatcher.scope?.value) === 2}>
            <Row>
              <Col md={6}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="electricLicenseNo"
                  label="N. licenza officina elettrica"
                  rules={{ required: errorFormLabels.REQUIRED, maxLength: 128 }}
                  register={register}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <legend className="col-form-label">Per la determinazione della quantità di gas impiegata per la produzione di energia elettrica:</legend>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <SelectWrapper
                  name="customAgency"
                  control={control}
                  label="Modalità determinazione quantità gas per produzione EE"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={pdrFieldsValues?.customAgency || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <Col md={6}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="officeTown"
                  label="Ufficio Tecnico di Finanza di"
                  rules={{ required: errorFormLabels.REQUIRED, maxLength: 128 }}
                  register={register}
                />
              </Col>
            </Row>
          </ShowOnCondition>
          <ShowOnCondition
            showWhen={
              parseInt(formWatcher.scope?.value) === 2 && 
              parseInt(formWatcher.customAgency?.value) === 1
            }
          >
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="reportProtocol"
                label="Verbale protocollo"
                rules={{ required: errorFormLabels.REQUIRED, maxLength: 128 }}
                register={register}
              />
            </Col>
            <Col md={6}>
              <DatePickerWrapper
                name="reportDate"
                control={control}
                label="Data firma"
                className="form-control"
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                disabled={canEdit ? false : true}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </ShowOnCondition>
        </div>
      </div>
    </ShowOnCondition>    
  )
}

export default FAttachment