import { Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import infoAllegati from './informativa-allegati.json'
import ShowOnCondition from '../../auth/ShowOnCondition.component'

type ContractAttachmentsInfoAlertProps = {
  entityName: keyof typeof infoAllegati
}

const ContractAttachmentsInfoAlert = ({
  entityName
}: ContractAttachmentsInfoAlertProps) => {
  return (
    <ShowOnCondition showWhen={!!infoAllegati[entityName]}>
      <Alert color="warning" className="mb-4">
        <h5 className="alert-heading">
          <FontAwesomeIcon icon="warning" className="me-2" /> 
          Non dimenticare
        </h5>
        <div dangerouslySetInnerHTML={{__html: infoAllegati[entityName]}}></div>
      </Alert>
    </ShowOnCondition>
  )
}

export default ContractAttachmentsInfoAlert