import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { useState } from "react"
import { Link } from "react-router-dom"

import CanI from "../../../../../auth/CanI.component"
import ButtonWithLoader from "../../../../../ButtonWithLoader/ButtonWithLoader.component"
import TdTable from "../../../../TdTable/TdTable.component"
import DeleteItem from "../../../../../DeleteItemModal/DeleteItemModal.component"
import ShowOnCondition from "../../../../../auth/ShowOnCondition.component"
import { getContractStatusIcon, getEntityPath, getContractTypeLabel } from "../../../../../../redux/contract/contract.utils"
import { HeaterContractListType } from "../../../../../../redux/list/list.types"
import { getContractCodeLabel } from "./utils"
import { useSelectEntityName } from "../../../../../../pages/providers/ListsPageProvider.provider"
import { useDeleteProposalDataMutation } from "../../../../../../redux/contract/contract.api"

type ContractTRowProps = {
  item: HeaterContractListType
  sortParam: string
}

const ContractTRow = ({
  item,
  sortParam,
}: ContractTRowProps) => {
  const entityName = useSelectEntityName()
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const toggleDelete = () => setDeleteIsOpen(prevState => !prevState)
  
  const [deleteItem, { isLoading: isDeletingItem }] = useDeleteProposalDataMutation()

  return (
    <tr>
      <TdTable
        sortParam={sortParam}
        sortingName="statusId"
        otherClasses={['text-center', 'status']}
      >
        <FontAwesomeIcon
          size="lg" 
          className="mr-2" 
          icon={getContractStatusIcon(parseInt(item.contractStatus?.value))} 
          title={item.contractStatus?.label}
        />
      </TdTable>
      <ShowOnCondition showWhen={entityName === "all"}>
        <TdTable 
          value={getContractTypeLabel(item.contractType)}
        />
      </ShowOnCondition>
      <TdTable 
        value={getContractCodeLabel(item)}
        sortParam={sortParam}
        sortingName="contractCode"
      />
      <TdTable value={item.customerType?.label} />
      <TdTable value={item.customerName} />
      <TdTable 
        value={item.customerEmail}
        otherClasses={['break-word']}
      />
      <TdTable 
        value={item.agent}
      />
      <TdTable 
        value={item.insertDate ? moment(item.insertDate).format('DD-MM-YYYY') : ""}
        sortParam={sortParam}
        sortingName="insertDate"
      />
      <TdTable 
        value={item.signingDate ? moment(item.signingDate).format('DD-MM-YYYY') : ""}
        sortParam={sortParam}
        sortingName="signingDate"
      />
      <TdTable otherClasses={["actions-col"]}>
        <div className="buttons-container">
          <CanI doWhat={"GET"} withPermissions={item.links} entityName={item.contractType}>
            {() => (
              <Link
                target="_blank"
                title="Vedi dettaglio"
                className="btn btn-outline-secondary btn-sm mx-1"
                to={`/${getEntityPath(item.contractType)}/${item.id}/`}
              >
                <FontAwesomeIcon size="sm" icon="eye" />
              </Link>
            )}
          </CanI>
          <CanI doWhat={"DELETE"} withPermissions={item.links} entityName={item.contractType}>
            {({ action }) => (
              <>
                <ButtonWithLoader
                  size="sm"
                  fontAwesomeIcon={['fas', 'trash-alt']}
                  isLoading={isDeletingItem}
                  outline
                  onClick={toggleDelete}
                  color="danger"
                />
                <DeleteItem
                  isOpen={deleteIsOpen}
                  toggle={toggleDelete}
                  deleteFn={async () => {
                    await deleteItem(action)
                    toggleDelete()
                  }}
                  isDeleting={isDeletingItem}
                />
              </>
            )}
          </CanI>
        </div>
      </TdTable>
    </tr>
  )
}

export default ContractTRow