import { useSelectEntityName } from "../../../../../pages/providers/ListsPageProvider.provider"
import { useFetchListDataQuery } from "../../../../../redux/list/list.api"
import { PodItemType } from "../../../../../redux/list/list.types"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import { buildFetchUrl } from "../../utils"
import PodTRow from "./PodTRow/PodTRow.component"

type PodTBodyProps = {
  sortParam: string
  filters: string
  page: number
}

const PodTBody = ({
  sortParam,
  filters,
  page,
}: PodTBodyProps) => {
  const entityName = useSelectEntityName()
  const { listItems } = useFetchListDataQuery({
    apiUrl: buildFetchUrl(entityName),
    sortParam,
    filters,
    page,
  }, {
    selectFromResult: ({ data }) => ({
      listItems: data.items
    })
  })
  
  return (
    <tbody>
      <ShowOnCondition showWhen={!listItems?.length}>
        <tr>
          <td colSpan={100} className="text-center">
            <strong>Nessun elemento trovato</strong>
          </td>
        </tr>
      </ShowOnCondition>
      <ShowOnCondition showWhen={!!listItems?.length}>
        {listItems.map((item: PodItemType) => (
          <PodTRow 
            key={item.id} 
            item={item}
            sortParam={sortParam}
          />
        ))}
      </ShowOnCondition>
    </tbody>
  )
}

export default PodTBody