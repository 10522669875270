import { FieldValues } from "react-hook-form"

import { setDefaultDate } from "../utils/utils"

export function setDefaultFormData(proposalData?: FieldValues) {
  return proposalData ? ({
    ...proposalData,
    employeeBirthDate: setDefaultDate(proposalData.employeeBirthDate),
    startDate: setDefaultDate(proposalData.startDate),
  }) : ({})
}