import { useState } from "react"

import { useSelectEntityName } from "../../../pages/providers/ListsPageProvider.provider"
import { useFetchListDataQuery, useFetchListFiltersQuery } from "../../../redux/list/list.api"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import WithSkeleton from "../../WithSkeleton/WithSkeleton.component"
import PodPdrLegend from "../PodPdrLegend/PodPdrLegend.component"
import TableFooter from "../TableFooter/TableFooter.component"
import { LIST_DEFAULT_ITEMS_PER_PAGE } from "../utils"
import PodFilters from "./PodFilters/PodFilters.component"
import PodTable from "./PodTable/PodTable.component"
import { buildExcelExportUrl, buildFetchUrl } from "./utils"

const PodFiltersWithSkeleton = WithSkeleton(PodFilters)
const PodTableWithSkeleton = WithSkeleton(PodTable)

const PodListContainer = () => {
  const entityName = useSelectEntityName()
  //Handle sorting, filters and selected page
  const [sortParam, setSortParam] = useState('-insertDate')
  const [filters, setFilters] = useState("")
  const [page, setPage] = useState(1)

  //Fetch filters
  const { 
    data: filtersFields, 
    isFetching: isFetchingFilters, 
    isLoading: isLoadingFilters, 
  } = useFetchListFiltersQuery({ entityName })
  const fetchingFilters = isFetchingFilters || isLoadingFilters

  //Fetch list data trigger
  const {
    data: listData,
    isFetching: isFetchingListData,
    isLoading: isLoadingListData, 
    refetch,
  } = useFetchListDataQuery({
    apiUrl: buildFetchUrl(entityName),
    sortParam,
    filters,
    page
  })

  return (
    <section className="list-container">
      <PodFiltersWithSkeleton
        isLoading={fetchingFilters}
        selectedFilters={filters}
        setFilters={setFilters}
        fieldsValues={filtersFields}
        refetch={refetch}
        setPage={setPage}
      />
      <PodPdrLegend />
      <PodTableWithSkeleton 
        isLoading={isLoadingListData}
        isFetching={isFetchingListData}
        sortParam={sortParam}
        setSortParam={setSortParam}
        page={page}
        filters={filters}
      />
      <ShowOnCondition showWhen={listData?.count > 0}>
        <TableFooter
          listData={listData}
          page={page}
          setPage={setPage}
          itemsPerPage={LIST_DEFAULT_ITEMS_PER_PAGE}
          buildExcelExportUrl={buildExcelExportUrl}
        />
      </ShowOnCondition>
    </section>
  )
}

export default PodListContainer