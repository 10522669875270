import { useCallback, useEffect, useState } from "react"

type UpdateExciseClassListHookProps = {
  fetchExciseClass: (values: Record<string, unknown>) => any
  entityName: string
  useType?: string
  atecoCode?: string
  vatClass?: string
  customerType?: Record<string, unknown>
  podFieldsValues: []
}
export const useUpdateExciseClassListHook = ({
  useType,
  fetchExciseClass,
  podFieldsValues,
  atecoCode,
  customerType,
  vatClass,
  entityName
}: UpdateExciseClassListHookProps) => {
  const [exciseClassList, setExciseClassList] = useState(podFieldsValues)
  const updateContractPriceListCode = useCallback(async (values: Record<string, unknown>) => {
    const response = await fetchExciseClass(values)
    setExciseClassList(response.data)
  }, [fetchExciseClass])

  //Fetch data on render if podData is set
  useEffect(() => {
    if(useType && atecoCode && customerType && vatClass) {
      updateContractPriceListCode({
        entityName,
        useType,
        atecoCode,
        vatClass,
        customerType: customerType.value
      })
    }
  }, [
    customerType,
    entityName,
    useType,
    atecoCode,
    vatClass,
    updateContractPriceListCode,
  ])

  return exciseClassList
}