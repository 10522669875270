export function isPreviousHolderRequired(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function isOldAccountHolderClientCodeNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isOldAccountHolderPersonTypeNeeded(entityName: string) {
  return (
    entityName === 'contextualTransferGas'
  ) ? true : false  
}