import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Container, Row, Col } from 'reactstrap'

import DefaultPageContainer from '../DefaultPageContainer/DefaultPageContainer.component'
import PageHeader from '../PageHeader/PageHeader.component'
import ContractsFormPageStyle from './style'
import ShowOnCondition from '../../components/auth/ShowOnCondition.component'
import GasAndPowerContainer from './GasAndPowerContainer/GasAndPowerContainer.component'
import HeaterContainer from './HeaterContainer/HeaterContainer.component'
import FixedTermJobContainer from './FixedTermJobContainer/FixedTermJobContainer.component'
import { ContractTabsMap, getEntityLabelFromPath, getEntityNameFromPath, HeaterTabsMap, JobTabsMap } from '../../redux/contract/contract.utils'
import { ContractsPageContext } from '../providers/ContractsPageProvider.provider'
import PermanentJobContainer from './PermanentJobContainer/PermanentJobContainer.component'

const ContractsFormPage = () => {
  const { pathEntityName, contractId } = useParams()
  const entityName = useMemo(() => getEntityNameFromPath(pathEntityName), [pathEntityName])
  const entityLabel = useMemo(() => getEntityLabelFromPath(pathEntityName), [pathEntityName])

  //Set shared contract data like entityName/contractId for easy retrieve in children components
  const [contractData, setContractData] = useState<{[key: string]: any}>({ entityName, contractId })
  const [selectedStep, setSelectedStep] = useState<ContractTabsMap | HeaterTabsMap | JobTabsMap>(ContractTabsMap.PROPOSAL_STEP)

  //Update entityName, contractId on params change...
  //unmount function => Reset store selected step to 1 when contractId changes
  useEffect(() => {
    setContractData(prevState => ({ ...prevState, contractId, entityName }))

    return () => { setSelectedStep(ContractTabsMap.PROPOSAL_STEP) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId, entityName])

  return (
    <ContractsPageContext.Provider
      value={{
        contractData,
        setContractData,
        selectedStep,
        setSelectedStep,
      }}
    >
      <DefaultPageContainer>
        <ContractsFormPageStyle>
          <PageHeader pageTitle={entityLabel} />
          <Container className="contract-container">
            <Row className="justify-content-center">
              <Col xs={12}>
                <ShowOnCondition showWhen={entityName === "changeSupplier"}>
                  <GasAndPowerContainer />
                </ShowOnCondition>
                <ShowOnCondition showWhen={entityName === "heater"}>
                  <HeaterContainer />
                </ShowOnCondition>
                <ShowOnCondition showWhen={entityName === "fixedTermContract"}>
                  <FixedTermJobContainer />
                </ShowOnCondition>
                <ShowOnCondition showWhen={entityName === "permanentContract"}>
                  <PermanentJobContainer />
                </ShowOnCondition>
              </Col>
            </Row>
          </Container>
        </ContractsFormPageStyle>
      </DefaultPageContainer>
    </ContractsPageContext.Provider>
  )
}

export default ContractsFormPage
