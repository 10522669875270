import { FieldValues } from "react-hook-form"
import { ListGroup } from "reactstrap"

import { useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { Permissions } from "../../../redux/api/types"
import { BankAccountItemData } from "../../../redux/contract/contract.types"
import { canI } from "../../auth/utils"
import BankAccountItem from "../BankAccountItem/BankAccountItem.component"

type BankAccountItemsProps = {
  permissions: Permissions
  bankAccountFieldsValues: FieldValues
  bankAccountItems: BankAccountItemData[] | []
}

const BankAccountItems = ({
  permissions,
  bankAccountItems,
  bankAccountFieldsValues,
}: BankAccountItemsProps) => {
  const entityName = useSelectEntityName()
  const canEdit = canI(`${entityName}BankAccountDetailCREATE`, permissions).abilityCheck

  return (
    <>
      {
        canEdit === false
        ? (
          <p>Non è necessario inserire o modificare i metodi di pagamento perché risulta già presente un Rid bancario di riferimento.</p>
        ) : (
          <>
            {bankAccountItems.length ? 
              <ListGroup>
                {bankAccountItems.map((item: BankAccountItemData, i: number) => (
                  <BankAccountItem 
                    key={i} 
                    item={item} 
                    permissions={permissions}
                    bankAccountFieldsValues={bankAccountFieldsValues}
                  />
                ))}
              </ListGroup>
            :
              <p>Nessuna <strong>opzione di pagamento</strong> presente</p>
            }
          </>
        )
      }
    </>
  )
}

export default BankAccountItems