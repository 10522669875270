import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"
import { contractApi } from "../../../../redux/contract/contract.api"

import { fetchElectricVendors } from "../../../../redux/contract/pod/pod.api"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { asyncFnWrapper, CustomerTypes, getCustomerType } from "../../utils/utils"
import { codiceFISCALE, euPhoneCheck, vatNumberRegExpCheck } from "../../utils/validations"
import { useWhenFieldChangesHook } from "../hooks/useWhenFieldChangesHook.hooks"
import { disconnectableVisibility, isAccountHolderAtecoCodeNeeded, isAnnualConsumptionNeeded, isIndustrySectorNeeded, isMeterReadingRequired, isOldAccountHolderClientCodeNeeded, isOldAccountHolderPersonTypeNeeded, isPreviousHolderRequired, isSupplyFormNeeded, isSupplyStartDateNeeded, isTemporaryConnectionNeeded, isVendorNameNeeded } from "./utils"

const PodSupply = ({
  canEdit,
  fieldsValues: podFieldsValues,
  customerType
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, setValue, formState: { errors } } = useFormContext()
  const formWatcher = watch()
  const [triggerFetchVendors] = fetchElectricVendors.useLazyQuerySubscription()
  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  const [fetchPostalCodes] = contractApi.endpoints.fetchPostalCodes.useLazyQuerySubscription()

  useWhenFieldChangesHook({
    watch,
    field: "contractType",
    set: "electricExciseClassId",
    to: null,
    setValue
  })
  useWhenFieldChangesHook({
    watch,
    field: "oldAccountHolderClientBusinessName",
    set: "oldAccountHolderPersonType",
    to: null,
    setValue
  })

  return (
    <ShowOnCondition showWhen={isSupplyFormNeeded(entityName)}>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="list" className="me-2" />
          Fornitura EE
        </div>
        <div className="grouped-body">
          <Row>
            <Col md="12">
              <SelectWrapper
                name="as"
                control={control}
                label="Il richiedente in qualità di"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={podFieldsValues?.as || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <ShowOnCondition showWhen={parseInt(formWatcher.as?.value) === 6}>
              <Col md={12}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="asOther"
                  label="Altro (specificare)"
                  rules={{ 
                    maxLength: 80,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
            </ShowOnCondition>
            <ShowOnCondition showWhen={isMeterReadingRequired(entityName)}>
              <Col md="12">
                <SelectWrapper
                  name="meterReading"
                  control={control}
                  label="Lettura contatore"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={podFieldsValues?.meterReading || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
                <p className="small text-muted">Selezionare 'Sì' solo per i contatori di energia elettrica trattati monorari ai sensi del TIS</p>
              </Col>
            </ShowOnCondition>
          </Row>
          <ShowOnCondition
            showWhen={
              isMeterReadingRequired(entityName) &&
              parseInt(formWatcher.meterReading?.value) === 1
            }
          >
            <Row>
              <Col md={12}>
                <DatePickerWrapper
                  name="meterReadingDate"
                  control={control}
                  label="Data lettura contatore"
                  className="form-control"
                  errors={errors}
                  showMonthDropdown
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  disabled={canEdit ? false : true}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <Col md={6}>
                <InputWrapper
                  type="number"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="activeEnergy"
                  label="Energia attiva (A0) in kWh"
                  rules={{ 
                    min: 0,
                    maxLength: 80,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <InputWrapper
                  type="number"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="reactiveEnergy"
                  label="Energia reattiva (R0) in kvart"
                  rules={{ 
                    min: 0,
                    maxLength: 80,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <InputWrapper
                  type="number"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="activePower"
                  label="Potenza attiva (P0) in kW"
                  rules={{ 
                    min: 0,
                    maxLength: 80,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
            </Row>
          </ShowOnCondition>
          <Row>
            <ShowOnCondition showWhen={isAnnualConsumptionNeeded(entityName)}>
              <Col>
                <InputWrapper
                  type="number"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="annualConsumption"
                  label="Consumo kWh / anno"
                  step=".01"
                  rules={{ 
                    min: 0,
                    maxLength: 80,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
            </ShowOnCondition>
            <ShowOnCondition showWhen={isVendorNameNeeded(entityName)}>
              <Col>
                <AsyncSelectWrapper
                  name="vendorName"
                  control={control}
                  register={register}
                  label="Attuale fornitore"
                  errors={errors}
                  isDisabled={canEdit ? false : true}
                  loadOptions={(input: string) => {
                    if(input.length > 2) {
                      return asyncFnWrapper({ searchedValue: input, entityName }, triggerFetchVendors)
                    }
                  }}
                  rules={{ required: errorFormLabels.REQUIRED }}
                  noOptionsMessage={() => 
                    <span className="autocomplete-suggestion">Indica le prime lettere dell'attuale fornitore per trovarlo nella lista</span>
                  }
                />
              </Col>
            </ShowOnCondition>
            <Col md={12}>
              <SelectWrapper
                name="contractType"
                control={control}
                label="Tipologia contratto"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={podFieldsValues?.contractType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <ShowOnCondition showWhen={parseInt(formWatcher.contractType?.value) === 2}>
              <>
                <Col md={3}>
                  <SelectWrapper
                    name="audaxToponymId"
                    control={control}
                    label="Toponimo"
                    isDisabled={canEdit ? false : true}
                    errors={errors}
                    register={register}
                    options={podFieldsValues?.toponymIc || []}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
                <Col md={7}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="audaxStreetName"
                    label="Indirizzo"
                    rules={{ 
                      required: errorFormLabels.REQUIRED,
                      maxLength: 80,
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={2}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="audaxStreetNo"
                    label="Civico"
                    rules={{ 
                      required: errorFormLabels.REQUIRED,
                      maxLength: 4,
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={10}>
                  <AsyncSelectWrapper
                    name="audaxTownName"
                    control={control}
                    register={register}
                    label="Comune"
                    errors={errors}
                    isDisabled={canEdit ? false : true}
                    loadOptions={(input: string) => {
                      if(input.length > 2) {
                        return asyncFnWrapper(input, fetchTowns)
                      }
                    }}
                    getOptionLabel={option => `${option.town} (${option.province})`}
                    getOptionValue={option => `${option.town} (${option.province})`}
                    rules={{ required: errorFormLabels.REQUIRED }}
                    whenFieldChanges={() => setValue("postalCode", null)}
                    noOptionsMessage={() => 
                      <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                    }
                  />
                </Col>
                <Col md={2}>
                  <SelectWrapper
                    name="audaxPostalCode"
                    control={control}
                    register={register}
                    label="CAP"
                    isDisabled={canEdit? false : true}
                    errors={errors}
                    rules={{ required: errorFormLabels.REQUIRED }}
                    watchedFieldProps={{
                      watch: watch,
                      watchedFieldName: "audaxTownName",
                      triggerWatchFn: fetchPostalCodes,
                      returnedValuePropertyName: "town",
                      defaultWatchedFieldValue: formWatcher.audaxTownName || null,
                    }}
                  />
                </Col>
              </>
            </ShowOnCondition>
            <ShowOnCondition showWhen={disconnectableVisibility(entityName)}>
              <Col md={12}>
                <SelectWrapper
                  name="disconnectable"
                  control={control}
                  label="Disalimentabile"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={podFieldsValues?.disconnectable || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
                <p className="small text-muted">Casistiche: apparecchiature medico-terapeutiche necessarie per l’esistenza in vita / pubblica utilità / art. 18.1c Del. AEEG 04/08</p>
              </Col>
              <ShowOnCondition showWhen={parseInt(formWatcher.disconnectable?.value) === 2}>
                <Col md={12}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="disconnectableReason"
                    placeholder="Motivazione"
                    label="Non disalimentabile: motivazione?"
                    rules={{ 
                      maxLength: 80,
                      required: errorFormLabels.REQUIRED
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
              </ShowOnCondition>
            </ShowOnCondition>
            <ShowOnCondition showWhen={isSupplyStartDateNeeded(entityName)}>
              <Col md={12}>
                <DatePickerWrapper
                  name="supplyStartDate"
                  control={control}
                  label="Fornitura in possesso da"
                  className="form-control"
                  errors={errors}
                  showMonthDropdown
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  disabled={canEdit ? false : true}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
            </ShowOnCondition>
            <ShowOnCondition showWhen={isTemporaryConnectionNeeded(entityName)}>
              <Col md={12}>
                <SelectWrapper
                  name="temporaryConnection"
                  control={control}
                  label="È una connessione temporanea?"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={podFieldsValues?.temporaryConnection || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <ShowOnCondition showWhen={parseInt(formWatcher.temporaryConnection?.value) === 1}>
                <Col md={6}>
                  <DatePickerWrapper
                    name="temporaryConnectionFrom"
                    control={control}
                    label="Periodo da"
                    className="form-control"
                    errors={errors}
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    disabled={canEdit ? false : true}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
                <Col md={6}>
                  <DatePickerWrapper
                    name="temporaryConnectionTo"
                    control={control}
                    label="Periodo a"
                    className="form-control"
                    errors={errors}
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    disabled={canEdit ? false : true}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
                <Col md={12}>
                  <SelectWrapper
                    name="temporaryConnectionUse"
                    control={control}
                    label="Uso dell'energia"
                    isDisabled={canEdit ? false : true}
                    errors={errors}
                    register={register}
                    options={podFieldsValues?.temporaryConnectionUse || []}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
              </ShowOnCondition>
            </ShowOnCondition>
          </Row>
          <ShowOnCondition showWhen={isPreviousHolderRequired(entityName)}>
            <Row>
              <Col md={12}>
                <legend className="col-form-label">Dati vecchio intestatario:</legend>
                <p className="small text-muted">
                  A seconda dei dati inseriti alcuni campi saranno da compilare obbligatoriamente. 
                  Ad esempio il campo "Partita IVA" diventa obbligatorio se si inserisce una "Ragione Sociale", 
                  diventa invece NON obbligatorio inserendo "Nome" e "Cognome" del vecchio intestatario.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <SelectWrapper
                  name="oldAccountHolderCustomerType"
                  control={control}
                  label="Tipologia cliente vecchio intestatario"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={podFieldsValues?.oldAccountHolderCustomerType || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <ShowOnCondition showWhen={isOldAccountHolderClientCodeNeeded(entityName)}>
                <Col md={12}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="oldAccountHolderClientCode"
                    label="Codice cliente"
                    rules={{ maxLength: 20 }}
                    register={register}
                    errors={errors}
                  />
                </Col>
              </ShowOnCondition>
              <ShowOnCondition showWhen={parseInt(formWatcher.oldAccountHolderCustomerType?.value) === 2}>
                <Col md={6}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="oldAccountHolderClientBusinessName"
                    label="Ragione Sociale"
                    rules={{ 
                      maxLength: 80, 
                      required: errorFormLabels.REQUIRED 
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={6}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="oldAccountHolderVatNumber"
                    label="Partita IVA"
                    rules={{ 
                      maxLength: 11,
                      required: errorFormLabels.REQUIRED,
                      pattern: {
                        value: /^[0-9]{11}$/,
                        message: errorFormLabels.INVALID_VAT
                      }
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
              </ShowOnCondition>
              <ShowOnCondition showWhen={parseInt(formWatcher.oldAccountHolderCustomerType?.value) === 1}>
                <Col md={6}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="oldAccountHolderName"
                    label="Nome"
                    rules={{ 
                      maxLength: 80,
                      required: errorFormLabels.REQUIRED,
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={6}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="oldAccountHolderSurname"
                    label="Cognome"
                    rules={{ 
                      maxLength: 80,
                      required: errorFormLabels.REQUIRED,
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={12}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="oldAccountHolderFiscalCode"
                    label="Codice fiscale"
                    rules={{ 
                      maxLength: 16,
                      required: errorFormLabels.REQUIRED,
                      validate: value => (codiceFISCALE(value) || vatNumberRegExpCheck(value)) || errorFormLabels.INVALID_CF_VAT
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
              </ShowOnCondition>
              <ShowOnCondition showWhen={isOldAccountHolderPersonTypeNeeded(entityName)}>
                <Col md={6}>
                  <SelectWrapper
                    name="oldAccountHolderPersonType"
                    control={control}
                    label="Il vecchio intestatario è"
                    isDisabled={canEdit ? false : true}
                    errors={errors}
                    register={register}
                    rules={{ required: errorFormLabels.REQUIRED }}
                    options={formWatcher.oldAccountHolderVatNumber ? (
                      (podFieldsValues?.oldAccountHolderPersonTypeBUS || [])
                    ) : (
                      (podFieldsValues?.oldAccountHolderPersonTypeDOM || [])
                    )}
                  />
                </Col>
                <ShowOnCondition showWhen={parseInt(formWatcher.oldAccountHolderPersonType?.value) === 1}>
                  <Col>
                    <InputWrapper
                      type="text"
                      className="form-control phone-number"
                      disabled={canEdit ? false : true}
                      name="oldAccountHolderPhone"
                      label="Telefono"
                      rules={{ 
                        maxLength: 20,
                        required: errorFormLabels.REQUIRED,
                        validate: value => euPhoneCheck(value) || errorFormLabels.INVALID_PHONE,
                      }}
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </ShowOnCondition>
                <ShowOnCondition showWhen={parseInt(formWatcher.oldAccountHolderPersonType?.value) === 2}>
                  <Col>
                    <SelectWrapper
                      name="unavailabilityReason"
                      control={control}
                      label="Motivo irreperibilità"
                      isDisabled={canEdit ? false : true}
                      errors={errors}
                      register={register}
                      rules={{ required: errorFormLabels.REQUIRED }}
                      options={formWatcher.oldAccountHolderVatNumber ? (
                        (podFieldsValues?.oldAccountHolderPersonTypeBUS || [])
                      ) : (
                        (podFieldsValues?.oldAccountHolderPersonTypeDOM || [])
                      )}
                    />
                  </Col>
                </ShowOnCondition>
              </ShowOnCondition>
              <ShowOnCondition 
                showWhen={
                  isOldAccountHolderPersonTypeNeeded(entityName) &&
                  parseInt(formWatcher.oldAccountHolderPersonType?.value) === 3
                }
              >
                <Col md={6}>
                  <DatePickerWrapper
                    name="deathDate"
                    control={control}
                    label="Data decesso"
                    className="form-control"
                    errors={errors}
                    showMonthDropdown
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    disabled={canEdit ? false : true}
                    rules={{ required: errorFormLabels.REQUIRED }}
                  />
                </Col>
                <Col md={6}>
                  <InputWrapper
                    type="text"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    name="deathPlace"
                    label="Luogo decesso"
                    rules={{ 
                      maxLength: 80,
                      required: errorFormLabels.REQUIRED,
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col>
                  <SelectWrapper
                    name="deceasedTie"
                    control={control}
                    label="Legame con il deceduto"
                    isDisabled={canEdit ? false : true}
                    errors={errors}
                    register={register}
                    rules={{ required: errorFormLabels.REQUIRED }}
                    options={podFieldsValues?.deceasedTie || []}
                  />
                </Col>
                <ShowOnCondition showWhen={formWatcher.deceasedTie?.label.toLowerCase() === 'altro'}>
                  <Col md={12}>
                    <InputWrapper
                      type="text"
                      className="form-control"
                      disabled={canEdit ? false : true}
                      name="otherTie"
                      label="Altro legame"
                      rules={{ 
                        maxLength: 80,
                        required: errorFormLabels.REQUIRED,
                      }}
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </ShowOnCondition>
              </ShowOnCondition>
            </Row>
          </ShowOnCondition>
          <ShowOnCondition
            showWhen={
              getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType!) &&
              isAccountHolderAtecoCodeNeeded(entityName)
            }
          >
            <Row>
              <Col>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="accountHolderAtecoCode"
                  label="Codice ATECO 2007"
                  rules={{ 
                    maxLength: 8,
                    required: errorFormLabels.REQUIRED,
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
            </Row>
          </ShowOnCondition>
          <ShowOnCondition
            showWhen={
              isIndustrySectorNeeded(entityName) && 
              (
                getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType!) ||
                parseInt(formWatcher.contractType?.value) === 4
              )
            }
          >
            <Row>
              <Col>
                <SelectWrapper
                  name="industrySector"
                  control={control}
                  label="Settore industriale/merceologico"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  rules={{ required: errorFormLabels.REQUIRED }}
                  options={podFieldsValues?.deceasedTie || []}
                />
              </Col>
            </Row>
          </ShowOnCondition>
        </div>
      </div>
    </ShowOnCondition>
  )
}

export default PodSupply