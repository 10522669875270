import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Button } from "reactstrap"

import ShowOnCondition from "../auth/ShowOnCondition.component"
import ScrollToTop from "../ScrollToTop/ScrollToTop.component"
import WithSpinner from "../WithSpinner/WithSpinner.component"
import BlockAllModal from "./BlockAllModal/BlockAllModal.component"
import CadastralDashboardControlButtons from "./CadastralDashboardControlButtons/CadastralDashboardControlButtons.component"
import CadastralDataItems from "./CadastralDataItems/CadastralDataItems.component"
import CadastralDataDashboardStyle from "./style"
import ValidateAllModal from "./ValidateAllModal/ValidateAllModal.component"
import { useSelectContractId, useSelectEntityName } from "../../pages/providers/ContractsPageProvider.provider"
import { useFetchContractBankAccountItemsQuery } from "../../redux/contract/bankAccountDetail/bankAccountDetail.api"
import { useBlockAllPdpMutation, useFetchCadastralDataQuery, useValidateAllPdpMutation } from "../../redux/contract/cadastralData/cadastralData.api"
import { useFetchContractProposalDataQuery, useFetchFormFieldsValuesQuery } from "../../redux/contract/contract.api"

const CadastralDataItemsWithSpinner = WithSpinner(CadastralDataItems)

const CadastralDataDashboard = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()

  const [blockAllIsOpen, setBlockAllIsOpen] = useState(false)
  const toggleBlockAll = () => setBlockAllIsOpen(prevState => !prevState)
  const [validateAllIsOpen, setValidateAllIsOpen] = useState(false)
  const toggleValidateAll = () => setValidateAllIsOpen(prevState => !prevState)

  const { data: formFields } = useFetchFormFieldsValuesQuery({ entityName })
  const { 
    data: proposalData, 
    isFetching: isFetchingProposal, 
    isLoading: isLoadingProposal,
  } = useFetchContractProposalDataQuery({ contractId, entityName })
  const proposalDataLoaded = !isFetchingProposal && !isLoadingProposal

  const {
    data: cadastralData,
    isFetching: isFetchingCadastralData,
    isLoading: isLoadingCadastralData,
  } = useFetchCadastralDataQuery({ contractId, entityName })
  const { 
    data: bankAccountItems, 
    isFetching: isFetchingBankItems, 
    isLoading: isLoadingBankItems,
  } = useFetchContractBankAccountItemsQuery({ contractId, entityName })
  const cadastralDataLoading = isFetchingCadastralData || isLoadingCadastralData || isFetchingBankItems || isLoadingBankItems
  
  const [triggerBlockAllPdp, { isLoading: isBlockingAllPdp }] = useBlockAllPdpMutation()
  const [triggerValidateAllPdp, { isLoading: isValidatingAllPdp }] = useValidateAllPdpMutation()

  return (
    <CadastralDataDashboardStyle>
      <ScrollToTop />
      <header>
        <h3 className="title">Elenco dei punti di fornitura</h3>
        <div>
          <ShowOnCondition showWhen={cadastralData?.canBlockAll === true}>
            <Button
              color="danger"
              size="sm"
              onClick={toggleBlockAll}
            >
              <FontAwesomeIcon icon="hand-paper" className="mr-1" />
              {' '}
              Blocca tutti i punti di fornitura
            </Button>
            <BlockAllModal 
              isOpen={blockAllIsOpen}
              toggle={toggleBlockAll}
              isSubmitting={isBlockingAllPdp}
              blockAllPdp={async () => {
                await triggerBlockAllPdp({ entityName, contractId })
                toggleBlockAll()
              }}
            />
          </ShowOnCondition>
          <ShowOnCondition showWhen={cadastralData?.canValidateAll === true}>
            <Button
              color="success"
              size="sm"
              onClick={toggleValidateAll}
              className="ms-2"
            >
              <FontAwesomeIcon icon="check-circle" className="mr-1" />
              {' '}
              Valida tutti i punti di fornitura
            </Button>
            <ValidateAllModal 
              isOpen={validateAllIsOpen}
              toggle={toggleValidateAll}
              isSubmitting={isValidatingAllPdp}
              validateAllPdp={async () => {
                await triggerValidateAllPdp({ entityName, contractId })
                toggleValidateAll()
              }}
            />
          </ShowOnCondition>
        </div>
      </header>
      <CadastralDataItemsWithSpinner 
        isLoading={cadastralDataLoading} 
        permissions={proposalData.links}
        cadastralFieldsValues={formFields?.cadastralData || {}}
        cadastralDataItems={cadastralData?.items || []}
        customerType={proposalData.customerType}
        bankAccountData={bankAccountItems}
      />
      {proposalDataLoaded &&
        <CadastralDashboardControlButtons 
          permissions={proposalData?.links}
          cadastralFieldsValues={formFields?.cadastralData || {}}
        />
      }
    </CadastralDataDashboardStyle>
  )
}

export default CadastralDataDashboard