import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Col, Form, Modal, ModalBody, ModalHeader, Row } from "reactstrap"

import ButtonWithLoader from "../../../ButtonWithLoader/ButtonWithLoader.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import { useSelectEntityName, useSetSelectedStep } from "../../../../pages/providers/ContractsPageProvider.provider"
import { useSubmitOtpCodeMutation } from "../../../../redux/contract/contract.api"
import { OtpSignerType } from "../../../../redux/contract/contract.types"
import { ContractTabsMap } from "../../../../redux/contract/contract.utils"
import { errorFormLabels } from "../../utils/formLabels"

type OtpSignatureModalProps = {
  signer: OtpSignerType
  isOpen: boolean
  toggle: () => void
  toggleFinalizationModal: () => void
  isLastSigner: boolean
}

type OtpSignatureFormType = {
  otpCode: string
  id: string
}

const OtpSignatureModal = ({
  signer,
  isOpen,
  toggle,
  toggleFinalizationModal,
  isLastSigner,
}: OtpSignatureModalProps) => {
  const entityName = useSelectEntityName()
  const setSelectedStep = useSetSelectedStep()!

  const { 
    register, 
    handleSubmit, 
    setFocus, 
    formState: { errors, isSubmitting } 
  } = useForm<OtpSignatureFormType>({
    defaultValues: {
      id: signer.id
    }
  })

  const [submitOtpSignature] = useSubmitOtpCodeMutation()
  const onSubmit = async (values: any) => {
    await submitOtpSignature({ values, entityName, isLastSigner })
    if(isLastSigner) {
      toggle()
      toggleFinalizationModal()
      setSelectedStep(ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setFocus("otpCode", { shouldSelect: true })
    }, 0)
  }, [isOpen, setFocus])

  return (
    <Modal 
      size="lg"
      backdrop="static"
      keyboard={false}
      isOpen={isOpen} 
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="file-signature" className="me-2" />
        OTP di firma remota
      </ModalHeader>
      <ModalBody>
        <p className="description"><strong>Attenzione!</strong> L’operazione di conferma richiede alcuni secondi: attendi il termine dell’operazione.</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="info-circle" size="lg" className="me-2" />
              Inserisci il codice che ti è arrivato via SMS
            </div>
            <div className="grouped-body">
              <Row>
                <Col>
                  <InputWrapper 
                    name="otpCode"
                    className="form-control"
                    register={register}
                    label="Firma OTP"
                    type="text"
                    rules={{ required: errorFormLabels.REQUIRED, maxLength: 6 }}
                    errors={errors}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="text-end">
            <ButtonWithLoader
              className="mb-5"
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting}
              label="Firma"
              fontAwesomeIcon={["fas", "file-signature"]}
            />
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default OtpSignatureModal