import { useState } from "react"

import ContractsListContainerStyle from "./style"
import WithSkeleton from "../../WithSkeleton/WithSkeleton.component"
import ContractLegend from "../ContractLegend/ContractLegend.component"
import ContractsFilters from "./ContractsFilters/ContractsFilters.component"
import ContractTable from "./ContractsTable/ContractTable.component"
import TableFooter from "../TableFooter/TableFooter.component"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import { buildFetchUrl } from "./utils"
import { LIST_DEFAULT_ITEMS_PER_PAGE } from "../utils"
import { useFetchListDataQuery, useFetchListFiltersQuery } from "../../../redux/list/list.api"
import { useSelectEntityName } from "../../../pages/providers/ListsPageProvider.provider"
import { Button, Collapse } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ContractsFiltersWithSkeleton = WithSkeleton(ContractsFilters)
const ContractTableWithSkeleton = WithSkeleton(ContractTable)

const FixedTermContractsListContainer = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const entityName = useSelectEntityName()
  //Handle sorting, filters and selected page
  const [sortParam, setSortParam] = useState('-insertDate')
  const [filters, setFilters] = useState("")
  const [page, setPage] = useState(1)

  //Fetch filters
  const {
    data: filtersFields,
    isFetching: isFetchingFilters,
    isLoading: isLoadingFilters,
  } = useFetchListFiltersQuery({ entityName })
  const fetchingFilters = isFetchingFilters || isLoadingFilters

  //Fetch list data trigger
  const {
    data: listData,
    isFetching: isFetchingListData,
    isLoading: isLoadingListData,
    refetch,
  } = useFetchListDataQuery({
    apiUrl: buildFetchUrl(entityName),
    sortParam,
    filters,
    page
  })

  return (
    <ContractsListContainerStyle>
      <div className="text-end">
        <Button
          color="primary"
          onClick={toggle}
          style={{ marginBottom: '1rem' }}
        >
          <FontAwesomeIcon icon="filter" className="me-2" />
          {isOpen ? "Nascondi" : "Mostra"} filtri
        </Button>
      </div>
      <Collapse isOpen={isOpen}>
        <ContractsFiltersWithSkeleton
          isLoading={fetchingFilters}
          selectedFilters={filters}
          setFilters={setFilters}
          fieldsValues={filtersFields}
          refetch={refetch}
          setPage={setPage}
        />
      </Collapse>
      <ContractLegend />
      <ContractTableWithSkeleton
        isLoading={isLoadingListData}
        isFetching={isFetchingListData}
        sortParam={sortParam}
        setSortParam={setSortParam}
        page={page}
        filters={filters}
        listData={listData}
      />
      <ShowOnCondition showWhen={listData?.count > 0}>
        <TableFooter
          listData={listData}
          page={page}
          setPage={setPage}
          itemsPerPage={LIST_DEFAULT_ITEMS_PER_PAGE}
        />
      </ShowOnCondition>
    </ContractsListContainerStyle>
  )
}

export default FixedTermContractsListContainer