import styled from "styled-components"

const UserMassiveCreationFormStyle = styled.div`
  padding-bottom: 2.5rem;

  .grouped {
    border-radius: .75rem;
    margin-bottom: 1.5rem;

    .grouped-header {
      border: 1px solid ${({ theme }) => theme.colors.grey};
      padding: .5rem .75rem;
      border-radius: .75rem .75rem 0 0;
      background-color: ${({ theme }) => theme.colors.dirtWhite};
      color: ${({ theme }) => theme.colors.darkGrey};

      .step {
        margin-right: .75rem;
        font-weight: 700;
        position: relative;
        color: ${({ theme }) => theme.colors.white};
        z-index: 1;
        padding: 0 .25rem;
  
        &:before {
          content: "";
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          z-index: -1;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #b1b1b1;
        }
      }
    }

    .grouped-body {
      border-radius: 0 0 .75rem .75rem;
      border: 1px solid ${({ theme }) => theme.colors.grey};
      border-top: none;
      font-size: .85rem;
      padding: 1rem .75rem;
    }
  }
  
  .dropzone-listgroup {
    &.single-file {
      .form-group:not(.m-0) {
        margin-bottom: .75rem !important;
      }
    }
  }
`

export default UserMassiveCreationFormStyle