import { api } from '../api'
import { getAPIUrl } from '../api/utils'
import { getEntityUriName } from '../contract/contract.utils'
import { UserDataType } from './user.types'

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    fetchUserData: build.query({
      query: (userId: string) => ({
        url: `${getAPIUrl()}/${getEntityUriName('user')}/${userId}/`
      }),
      providesTags: (result, error, userId) => [{ type: 'User', id: userId }]
    }),
    submitUserData: build.mutation({
      query: (values: UserDataType) => ({
        url: `${getAPIUrl()}/${getEntityUriName('user')}${
          values.id ? `/${values.id}` : ``
        }`,
        method: values.id ? 'PUT' : 'POST',
        body: values
      }),
      invalidatesTags: (result, error, values) => [
        { type: 'Contract', id: result.id }
      ]
    }),
    fetchUserParent: build.query({
      query: (searchedValue: string) => ({
        url: `${getAPIUrl()}/users/parent?username=${searchedValue}`
      }),
      transformResponse: (response: { data: any }, meta, arg) => response.data
    }),
    submitMassiveCreation: build.mutation({
      query: values => ({
        url: '/users/massive-insertions',
        method: 'POST',
        body: values
      })
    }),
    fetchSalesOffersDownloads: build.query({
      query: ({ userId }) => ({
        url: `users/${userId}/sale-offers/active`
      }),
      transformResponse: (response: { data: any }, meta, arg) => response.data
    }),
    downloadSaleOfferPdf: build.query({
      query: ({ saleOfferId }) => ({
        url: `users/sale-offers/${saleOfferId}/pdf`
      }),
      transformResponse: (response: { data: any }, meta, arg) => response.data
    }),
    updateUserVisibility: build.mutation({
      query: ({ apiUrl, values }) => ({
        url: apiUrl,
        method: 'PATCH',
        body: values
      }),
      invalidatesTags: ['List']
    })
  })
})

export const {
  useFetchUserDataQuery,
  useSubmitUserDataMutation,
  useSubmitMassiveCreationMutation,
  useFetchSalesOffersDownloadsQuery,
  useUpdateUserVisibilityMutation
} = userApi

export const {
  endpoints: { fetchUserParent, downloadSaleOfferPdf }
} = userApi
