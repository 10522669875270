import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FieldValues, useForm } from "react-hook-form"
import { Button, Form } from "reactstrap"

import UserMassiveCreationFormStyle from "./style"
import AttachmentsDropzone from "../../AttachmentsDropzone/AttachmentsDropzone.component"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import InputWrapper from "../inputFields/Input/InputWrapper.component"
import { useSubmitMassiveCreationMutation } from "../../../redux/user/user.api"
import { errorFormLabels } from "../utils/formLabels"
import { getTemplateDownloadPath } from "./utils"
import ShowOnCondition from "../../auth/ShowOnCondition.component"

const UserMassiveCreationForm = () => {
  const { register, setValue, reset, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm()
  const formWatcher = watch()

  const [submitMassiveCreation] = useSubmitMassiveCreationMutation()

  const onSubmit = async (values: FieldValues) => {
    const builtObj = await buildObjectToSend(values.massiveCreationExcel);
    await submitMassiveCreation(builtObj)
    reset()
  }

  const buildObjectToSend = (obj: FieldValues) => {
    const formattedObj = {
      id: obj.id,
      userFileName: obj.userFileName,
      fileBase64: obj.fileBase64,
      size: obj.size
    }
    return formattedObj
  }

  return (
    <UserMassiveCreationFormStyle>
      <div className="grouped">
        <div className="grouped-header">
          <span className="step">1</span>
          Scarica il template del file excel
        </div>
        <div className="grouped-body text-center">
          <Button
            color="secondary"
            target="_blank"
            href={`${getTemplateDownloadPath()}/templates/v1/excels/Modello.xlsx`}
          >
            <FontAwesomeIcon icon="download" className="me-2" />
            <span>Scarica template</span>
          </Button>
        </div>
      </div>
      <div className="grouped">
        <div className="grouped-header">
          <span className="step">2</span>
          Compila il file excel
        </div>
        <div className="grouped-body">
          Compila il file seguendo l'impostazione indicata nel template, così che durante il processo
          di importazione non si verifichino errori dovuti a una scorretta formattazione dei campi e dei relativi valori richiesti.<br />
          <strong>Nota bene</strong>: la prima riga del template fornito non sarà processata, ricordati quindi
          di non modificarla/sovrascriverla perché tali valori non saranno comunque considerati.
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="grouped">
          <div className="grouped-header">
            <span className="step">3</span>
            Carica il file excel<sup>*</sup>
          </div>
          <div className="grouped-body">
            <div className="dropzone-container">
              <AttachmentsDropzone
                isMulti={false}
                addFile={(values: FieldValues) => setValue("massiveCreationExcel", values)}
                openDialogWithBtn={true}
                acceptedFiles={{
                  'application/vnd.ms-excel': [],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
                }}
              />
              <InputWrapper
                type="text"
                className="form-control"
                label="File"
                name="massiveCreationExcel.userFileName"
                readOnly
                register={register}
                errors={errors}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
              <ShowOnCondition showWhen={formWatcher.massiveCreationExcel?.size}>
                <InputWrapper
                  name="massiveCreationExcel.size"
                  type="text"
                  readOnly
                  label="Dimensione"
                  className="form-control"
                  register={register}
                />
              </ShowOnCondition>
            </div>
            <div className="text-end">
              <ButtonWithLoader
                isLoading={isSubmitting}
                type="submit"
                disabled={isSubmitting}
                label="Carica File"
                fontAwesomeIcon={["fas", "upload"]}
              />
            </div>
          </div>
        </div>
      </Form>
      <div className="grouped">
        <div className="grouped-header">
          <span className="step">4</span>
          Ricevi la notifica ad importazione avvenuta
        </div>
        <div className="grouped-body">
          Caricato il file, un processo in backgroud elaborerà la richiesta e gestirà l'importazione
          massiva degli utenti (il completamento dell'operazione può richiedere diversi minuti).<br />
          Una volta terminato, ti invieremo un'email per notificarne l'esito.
        </div>
      </div>
    </UserMassiveCreationFormStyle>
  )
}

export default UserMassiveCreationForm