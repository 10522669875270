import classNames from "classnames"
import { Table } from "reactstrap"
import PodTBody from "./PodTBody/PodTBody.component"
import PodTHeader from "./PodTHeader/PodTHeader.component"

type PodTableProps = {
  sortParam: string
  setSortParam: (value: string) => void
  page: number
  filters: string
  isFetching: boolean
}

const PodTable = ({
  isFetching,
  ...rest
}: PodTableProps) => {
  return (
    <Table 
      responsive
      className={
        classNames({
          'is-fetching': isFetching === true
        })
      }
    >
      <PodTHeader
        {...rest}
      />
      <PodTBody
        {...rest}
      />
    </Table>
  )
}

export default PodTable