import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { Form, Col, Row } from "reactstrap"

import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { useSubmitCadastralItemMutation } from "../../../redux/contract/cadastralData/cadastralData.api"
import { contractApi } from "../../../redux/contract/contract.api"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import { canI } from "../../auth/utils"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import AsyncSelectWrapper from "../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import InputWrapper from "../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../utils/formLabels"
import { asyncFnWrapper } from "../utils/utils"
import PropertyDeclaration from "./PropertyDeclaration/PropertyDeclaration.component"
import { isPropertyDeclarationRequired, setDefaultFormData } from "./utils"

export type CadastralDataFormProps = {
  cadastralFieldsValues: FieldValues
  cadastralDetailData?: any
  toggleCadastralDataDetailModal: () => void
}

const CadastralDataForm = ({
  cadastralFieldsValues,
  cadastralDetailData,
  toggleCadastralDataDetailModal
}: CadastralDataFormProps) => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  let canEdit = true
  if(cadastralDetailData) {
    canEdit = canI(`${entityName}CadastralDataUPDATE`, cadastralDetailData.links).abilityCheck
  }
  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  const [fetchPostalCodes] = contractApi.endpoints.fetchPostalCodes.useLazyQuerySubscription()
  const formProps = useForm<FieldValues>({
    defaultValues: {
      ...setDefaultFormData(cadastralDetailData),
      contractId,
    }
  })
  const { register, control, watch, setValue, handleSubmit, formState: { errors, isSubmitting } } = formProps
  
  const [submitCadastralItem] = useSubmitCadastralItemMutation()
  const onSubmit = async (values: FieldValues) => {
    await submitCadastralItem({ values, entityName })
    toggleCadastralDataDetailModal()
  }

  return (
    <FormProvider {...formProps}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="list" className="me-2" />
            Dati fornitura
          </div>
          <div className="grouped-body">
            <Row>
              <Col md={2}>
                <SelectWrapper
                  name="toponymId"
                  control={control}
                  label="Toponimo"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={cadastralFieldsValues.supplyToponym || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <Col md={8}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="streetName"
                  label="Indirizzo"
                  placeholder="Indirizzo"
                  rules={{ required: errorFormLabels.REQUIRED }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={2}>
                <InputWrapper
                  type="text"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  name="streetNo"
                  label="Civico"
                  placeholder="Civico"
                  rules={{ required: errorFormLabels.REQUIRED }}
                  register={register}
                  errors={errors}
                />
              </Col>
              <Col md={10}>
                <AsyncSelectWrapper
                  name="townName"
                  control={control}
                  register={register}
                  label="Comune"
                  errors={errors}
                  isDisabled={canEdit ? false : true}
                  loadOptions={(input: string) => {
                    if(input.length > 2) {
                      return asyncFnWrapper(input, fetchTowns)
                    }
                  }}
                  getOptionLabel={option => `${option.town} (${option.province})`}
                  getOptionValue={option => `${option.town} (${option.province})`}
                  rules={{ required: errorFormLabels.REQUIRED }}
                  whenFieldChanges={() => setValue("postalCode", null)}
                  noOptionsMessage={() => 
                    <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                  }
                />
              </Col>
              <Col md={2}>
                <SelectWrapper
                  name="postalCode"
                  control={control}
                  register={register}
                  label="CAP"
                  isDisabled={canEdit? false : true}
                  errors={errors}
                  rules={{ required: errorFormLabels.REQUIRED }}
                  watchedFieldProps={{
                    watch: watch,
                    watchedFieldName: "townName",
                    triggerWatchFn: fetchPostalCodes,
                    returnedValuePropertyName: "town",
                    defaultWatchedFieldValue: cadastralDetailData?.townName || null,
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
        <ShowOnCondition showWhen={isPropertyDeclarationRequired(entityName)}>
          <PropertyDeclaration
            canEdit={canEdit}
            fieldsValues={cadastralFieldsValues}
          />
        </ShowOnCondition>
        
        {canEdit &&
          <div className="text-end">
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting}
              label="Salva"
              fontAwesomeIcon={["fas", "save"]}
            />
          </div>
        }
      </Form>
    </FormProvider>
  )
}

export default CadastralDataForm