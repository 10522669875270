import { FieldValues } from "react-hook-form"

import { CustomerTypes, getCustomerType, setDefaultDate } from "../utils/utils"
import { isResidentNeeded } from "./PdrOfferConfig/utils"

export const DefaultSelectValues = {
  GAS_USE_TYPE_ID: { label: "Domestico", value: "1" },
  GAS_EXCISE_CLASS_ID: { label: "No", value: "13"},
  IS_RESIDENT: { value: "1", label: "Sì" },
  IS_NOT_RESIDENT: { value: "2", label: "No" },
  IS_INVOICE_DIFFERENT_EMAIL: { value: "2", label: "No" },
  BILLING_SHIPPING_ADDRESS: { value: "1", label: "No" }
}

type DefaultFormDataType = {
  pdrFieldsValues: FieldValues
  pdrData?: FieldValues
  customerType: Record<string, string> 
  entityName: string
}
export function setDefaultFormData({
  pdrFieldsValues, 
  pdrData,
  customerType,
  entityName,
}: DefaultFormDataType) {
  return pdrData ? ({
    ...pdrData,
    of: setDefaultDate(pdrData.of),
    reportDate: setDefaultDate(pdrData.reportDate),
    serviceStartDate: setDefaultDate(pdrData.serviceStartDate),
    meterReadingDate: setDefaultDate(pdrData.meterReadingDate),
    supplyStartDate: setDefaultDate(pdrData.supplyStartDate),
    deathDate: setDefaultDate(pdrData.deathDate),
  }) : ({
    isResident: (
      getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType) &&
      isResidentNeeded(entityName)
    ) ? DefaultSelectValues.IS_NOT_RESIDENT : undefined,
    billingShippingAddress: DefaultSelectValues.BILLING_SHIPPING_ADDRESS,
    isInvoiceDifferentEmail: DefaultSelectValues.IS_INVOICE_DIFFERENT_EMAIL,
  })
}