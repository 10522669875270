import React from 'react'
import { ToastContainer } from "react-toastify"
import { ThemeProvider } from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'

import GlobalStyles from './styles/GlobalStyles.styles'
import WithSpinner from './components/WithSpinner/WithSpinner.component'
import AppRouter from './components/AppRouter/AppRouter.component'
import { appTheme } from './styles/ThemeVariables'
import { useCheckUserSessionQuery } from './redux/auth/auth.api'

//Font awesome library
registerLocale("it", it)
setDefaultLocale("it")
library.add(fas)
const AppRouterWithSpinner = WithSpinner(AppRouter)

function App() {
  const { isLoading } = useCheckUserSessionQuery(undefined)
  //Open pub/sub connection with firebase real time db for manintenance updates

  return (
    <ThemeProvider theme={appTheme}>
      <GlobalStyles />
      <AppRouterWithSpinner isLoading={isLoading} />
      <ToastContainer autoClose={2000} />
    </ThemeProvider>
  )
}

export default App