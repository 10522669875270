export function isSupplyFormNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isMeterReadingNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function calculateDaysBeforeNow(days: number) {
  const dateToCalculate = new Date()
  dateToCalculate.setDate(dateToCalculate.getDate() - days)
  return dateToCalculate
}

export function isSupplyStartDateNeeded(entityName: string) {
  return (
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function isAccuntHolderAtecoCodeNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}