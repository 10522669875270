import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DropdownActiveCheck from '../DropdownActiveCheck/DropdownActiveCheck.component'
import CanI from '../../auth/CanI.component'
import { Entity } from '../../../redux/auth/auth.reducer'
import { getEntityPath, getEntityPluralName } from '../../../redux/contract/contract.utils'
import { selectCurrentUser } from '../../../redux/auth/auth.selectors'
import { useAppSelector } from '../../../redux/hooks'
import { NavLink } from 'react-router-dom'

type ContractsMenuProps = {
  contract: Pick<Entity, "name" | "entityName" | "icon">
}

const ContractsMenu = ({
  contract
}: ContractsMenuProps ) => {
  const { entityName, name, icon } = contract
  const user = useAppSelector(selectCurrentUser)!

  return (
    <DropdownActiveCheck searchedValue={getEntityPath(entityName)}>
      {props => (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret {...props}>
            <FontAwesomeIcon icon={['fas', icon]} />{' '}
            {name}
          </DropdownToggle>
          <DropdownMenu>
            <CanI doWhat={"CREATE"} withPermissions={user.links} entityName={entityName}>
              {() => (
                <DropdownItem tag="div">
                  <NavLink
                    end
                    className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                    to={`/${getEntityPath(entityName)}`}
                  >
                    Nuovo
                  </NavLink>
                </DropdownItem>
              )}
            </CanI>
            <CanI doWhat={"GET"} withPermissions={user.links} entityName={getEntityPluralName(entityName!)}>
              {() => (
                <DropdownItem tag="div">
                  <NavLink 
                    className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                    to={`/${getEntityPath(entityName)}/all`}
                  >
                    Vedi tutti
                  </NavLink>
                </DropdownItem>
              )}
            </CanI>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </DropdownActiveCheck>
  )
}

export default ContractsMenu