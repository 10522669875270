import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BankAccountForm from '../../forms/BankAccountForm/BankAccountForm.component'
import { Permissions } from '../../../redux/api/types'
import { FieldValues } from 'react-hook-form'

type BankAccountDetailModalProps = {
  isOpen: boolean
  toggle: () => void
  permissions: Permissions
  bankAccountFieldsValues: FieldValues
  bankAccountDetailData?: Record<string, unknown>
}

const BankAccountDetailModal = ({
  isOpen,
  toggle,
  ...rest
}: BankAccountDetailModalProps) => (
  <div className="action-modal">
    <Modal className="status-modal" size="lg" backdrop="static" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="money-bill-wave" className="me-2" />
        Dati pagamento
      </ModalHeader>
      <ModalBody>
        <BankAccountForm 
          toggleBankAccountDetailModal={toggle}
          {...rest}
        />
      </ModalBody>
    </Modal>
  </div>
)

export default BankAccountDetailModal
