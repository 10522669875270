import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ContractAttachmentsInfoAlert from './ContractAttachmentsInfoAlert/ContractAttachmentsInfoAlert.component'
import infoAllegati from './ContractAttachmentsInfoAlert/informativa-allegati.json'
import { SignatureTypes } from '../../redux/auth/auth.utils'
import OtpFormContainer from '../forms/OtpForm/OtpFormContainer.component'
import PaperSignatureFormContainer from '../forms/PaperSignatureForm/PaperSignatureFormContainer.component'
import { useSelectEntityName } from '../../pages/providers/ContractsPageProvider.provider'
import CommitContractForm from '../forms/CommitContractForm/CommitContractForm.component'

type ContractFinalizationModalProps = {
  isOpen: boolean
  toggle: () => void
  signatureType?: number
  contractId: string
  entityName: string
}

const ContractFinalizationModal = ({
  isOpen,
  toggle,
  signatureType,
  ...rest
}: ContractFinalizationModalProps) => {
  const entityName = useSelectEntityName()
  
  return (
    <Modal 
      modalClassName="signature-modal status-modal" 
      size="xl" 
      backdrop="static" 
      isOpen={isOpen} 
      toggle={toggle}
      keyboard={false}
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="signature" className="me-2" />
        Finalizzazione contratto
      </ModalHeader>
      <ModalBody>
        <ContractAttachmentsInfoAlert 
          entityName={entityName as (keyof typeof infoAllegati)}
        />
        {signatureType && signatureType === SignatureTypes.OTP_SIGNATURE &&
          <OtpFormContainer
            toggleFinalizationModal={toggle}
            {...rest}
          />
        }
        {signatureType && signatureType === SignatureTypes.PAPER_SIGNATURE &&
          <PaperSignatureFormContainer 
            toggleFinalizationModal={toggle}
            {...rest} 
          />
        }
        {signatureType && signatureType === SignatureTypes.COMMIT_SIGNATURE &&
          <CommitContractForm />
        }
      </ModalBody>
    </Modal>
  )
}

export default ContractFinalizationModal
