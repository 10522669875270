import { ButtonProps } from 'reactstrap'

import { useAppSelector } from '../../redux/hooks'
import { selectCurrentUserRole } from '../../redux/auth/auth.selectors'
import { UserRoles } from '../../redux/auth/auth.utils'
import { fetchExcelDownloadLink } from '../../redux/list/list.api'
import { downloadFile } from './utils'
import ButtonWithLoader from '../ButtonWithLoader/ButtonWithLoader.component'
import { useState } from 'react'

type DownloadExcelButtonProps = {
  downloadLink: string
  label: string
} & ButtonProps

const DownloadExcelButton = ({ 
  downloadLink, 
  label,
  ...rest
}: DownloadExcelButtonProps) => {
  const userRole = useAppSelector(selectCurrentUserRole)

  //Handle excel download
  const [fetchExcelFile] = fetchExcelDownloadLink.useLazyQuerySubscription()
  const [fetchingFile, setFetchingFile] = useState(false)
  const downloadExcel = async () => {
    if(
      userRole === UserRoles.USER_ROLE_VERIFIER ||
      userRole === UserRoles.USER_ROLE_ADMIN
    ) {
      setFetchingFile(true)
      const { data } = await fetchExcelFile(downloadLink)      
      downloadFile(data.body64, data.title)
      setFetchingFile(false)
    }
  }

  return(
    <ButtonWithLoader 
      isLoading={fetchingFile}
      outline
      color="secondary"
      fontAwesomeIcon="file-excel"
      label={label}
      onClick={downloadExcel}
      {...rest}
    />
  )
}

export default DownloadExcelButton
