import { useCallback, useEffect, useState } from "react"

type UpdateRemiCodesHookProps = {
  entityName: string,
  cadastralDataId: string
  fetchRemiCodes: (values: Record<string, unknown>) => any
}

export const useUpdateRemiCodesHook = ({
  entityName,
  cadastralDataId,
  fetchRemiCodes
}: UpdateRemiCodesHookProps) => {
  const [ remiCodes, setRemiCodes ] = useState([])
  const updateRemiCodes = useCallback(async (values: Record<string, unknown>) => {
    const response = await fetchRemiCodes(values)
    setRemiCodes(response.data)
  }, [fetchRemiCodes])

  useEffect(() => {
    if(cadastralDataId) {
      updateRemiCodes({ entityName, cadastralDataId })
    }
  }, [cadastralDataId, entityName, updateRemiCodes])

  return remiCodes
}