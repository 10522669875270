import { DefaultTheme } from "styled-components"

export const appTheme: DefaultTheme = {
  borderRadius: "10px",

  colors: {
    white: "#f1f1f1",
    grey: '#ddd',
    darkGrey: "#666",
    lightGrey: "#999",
    dirtWhite: "#f6f6f6",
    blue: '#337ab7',
    abBlue: "#0065ab",
    lightBlue: "#c6d7e6",
    black: "#333",
    violet: "#af24e8",
    orange: "#ff8300",
    ligthIndaco: "#1abed9",
    green: "#4db733",
    yellow: "#d3d72c",
    red: "#dc3545",

    links: {
      main: "#81c3f1",
      hover: "#ddd",
      focus: "",
    }
  },


  boxShadow: '0 0 15px -10px #656565',
}