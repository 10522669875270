import moment from "moment"
import { FieldValues } from "react-hook-form"

export function setDefaultFiltersValues() {
  return {
    statusId: null,
    customerType: null,
    roleId: null,
    companyId: null,
    signingDateStart: null,
    signingDateEnd: null,
    insertDateStart: null,
    insertDateEnd: null,
    note: null,
    report: null,
    hasReconsideration: null,
    relatedTransfer: null,
    relatedChangeSupplier: null,
    attachmentsIntegrationStatus: null,
    contractCode: null,
    customerName: null,
    customerEmail: null,
    agent: null,
  }
}

export function parseDate(value: string) {
  return moment(value).format('YYYY-MM-DD')
}

export function setQueryString(values: FieldValues) {
  let queryParams: string[] = []
  const keys = Object.keys(values) as (keyof typeof values)[]
  keys.forEach(itemKey => {
    if(values[itemKey]) {
      if(itemKey === 'signingDateStart' || itemKey === 'insertDateStart') {
        queryParams = [...queryParams, `${itemKey}=${parseDate(values[itemKey])}`]
      }
      else if(itemKey === 'signingDateEnd' || itemKey === 'insertDateEnd') {
        queryParams = [...queryParams, `${itemKey}=${parseDate(values[itemKey])} 23:59:59.999`]
      }
      else if(typeof values[itemKey] === 'object') {
        queryParams = [...queryParams, `${itemKey}=${encodeURIComponent(values[itemKey].value)}`]
      }
      else {
        queryParams = [...queryParams, `${itemKey}=${encodeURIComponent(values[itemKey])}`]
      }
    }
  })

  return queryParams
}