import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import { useSelectEntityName } from "../../../../pages/providers/ContractsPageProvider.provider"
import ShowOnCondition from "../../../auth/ShowOnCondition.component"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { getRequestTypeLabel, isAnnualConsumptionNeeded, isConnectionVoltageIdNeeded, isCurrentSupplierNeeded, isEneltelDisabled, isEneltelNeeded, isEneltelRequired, isNoDisabled, isOriginMarketNeeded, isPodNoRequired, isRequestTypeNeeded, isServiceStartDateNeeded } from "./utils"

const PodGeneralData = ({
  canEdit,
  fieldsValues: podFieldsValues,
  userRole,
}: FormProps) => {
  const entityName = useSelectEntityName()
  const { register, control, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="file" className="me-2" />
        Dati generali
      </div>
      <div className="grouped-body">
        <Row>
          <Col>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={isNoDisabled(entityName, userRole!, formWatcher, canEdit!)}
              name="no"
              label="Codice POD"
              placeholder="IT + 12 caratteri"
              maxLength={14}
              rules={{ 
                pattern: {
                  value: /^[a-zA-Z0-9]{14}$/,
                  message: "Deve essere composto da 14 caratteri!",
                },
                required: isPodNoRequired(entityName, formWatcher) ? errorFormLabels.REQUIRED : false 
              }}
              register={register}
              errors={errors}
            />
          </Col>
          <ShowOnCondition showWhen={isEneltelNeeded(entityName)}>
            <Col>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={isEneltelDisabled(userRole!, formWatcher, canEdit!)}
                name="eneltel"
                label="Codice Eneltel"
                placeholder="Codice formato da 9 caratteri"
                rules={{ 
                  maxLength: 9,
                  required: isEneltelRequired(entityName, formWatcher) ? errorFormLabels.REQUIRED : false
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition showWhen={isRequestTypeNeeded(entityName)}>
            <Col md="12">
              <legend className="col-form-label">
                {getRequestTypeLabel(entityName).title}
              </legend>
            </Col>
            <Col md="12">
              <SelectWrapper
                name="requestType"
                control={control}
                label={getRequestTypeLabel(entityName).label}
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={podFieldsValues?.requestType || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition showWhen={isConnectionVoltageIdNeeded(entityName, formWatcher)}>
            <Col md={6}>
              <SelectWrapper
                name="connectionVoltageId"
                control={control}
                label="Tensione"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={podFieldsValues?.connectionVoltage || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
            <ShowOnCondition showWhen={entityName === "changeSupplier"}>
              <Col>
                <InputWrapper
                  type="number"
                  className="form-control"
                  disabled={canEdit ? false : true}
                  step=".01"
                  name="powerValue"
                  label="Potenza disponibile in KW"
                  rules={{ 
                    maxLength: 20,
                    min: 0,
                    required: errorFormLabels.REQUIRED
                  }}
                  register={register}
                  errors={errors}
                />
              </Col>
            </ShowOnCondition>
            <ShowOnCondition showWhen={entityName !== "changeSupplier"}>
              <Col md={12}>
                <SelectWrapper
                  name="powerValue"
                  control={control}
                  label="Potenza disponibile in KW"
                  isDisabled={canEdit ? false : true}
                  errors={errors}
                  register={register}
                  options={podFieldsValues?.powerValue || []}
                  rules={{ required: errorFormLabels.REQUIRED }}
                />
              </Col>
              <ShowOnCondition showWhen={parseInt(formWatcher.powerValue?.value) === 23}>
                <Col md={6}>
                  <InputWrapper
                    type="number"
                    className="form-control"
                    disabled={canEdit ? false : true}
                    step=".01"
                    name="customPower"
                    label="Specificare la potenza in KW"
                    rules={{ 
                      maxLength: 20,
                      min: 30,
                      required: errorFormLabels.REQUIRED
                    }}
                    register={register}
                    errors={errors}
                  />
                </Col>
              </ShowOnCondition>
            </ShowOnCondition>
          </ShowOnCondition>
          <ShowOnCondition showWhen={isServiceStartDateNeeded(entityName)}>
            <Col md={12}>
              <DatePickerWrapper
                name="serviceStartDate"
                control={control}
                label="Da non eseguire prima del"
                className="form-control"
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                disabled={canEdit ? false : true}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition showWhen={isOriginMarketNeeded(entityName)}>
            <Col md={6}>
              <SelectWrapper
                name="originMarket"
                control={control}
                label="Mercato di provenienza"
                isDisabled={canEdit ? false : true}
                errors={errors}
                register={register}
                options={podFieldsValues?.originMarket || []}
                rules={{ required: errorFormLabels.REQUIRED }}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition showWhen={isCurrentSupplierNeeded(entityName)}>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="currentSupplier"
                label="Attuale fornitore"
                rules={{ 
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </ShowOnCondition>
          <ShowOnCondition showWhen={isAnnualConsumptionNeeded(entityName)}>
            <Col>
              <InputWrapper
                type="number"
                className="form-control"
                disabled={canEdit ? false : true}
                name="annualConsumption"
                label="Consumo annuo (KW)"
                rules={{ 
                  min: 0,
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED
                }}
                register={register}
                errors={errors}
              />
            </Col>
          </ShowOnCondition>
        </Row>
      </div>
    </div>
  )
}

export default PodGeneralData