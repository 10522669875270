import { useMemo, useState } from "react"

import { useSelectContractId, useSelectEntityName } from "../../pages/providers/ContractsPageProvider.provider"
import { useFetchContractBankAccountItemsQuery } from "../../redux/contract/bankAccountDetail/bankAccountDetail.api"
import { selectContractProposalData, selectFormFieldsValues } from "../../redux/contract/contract.selectors"
import { useAppSelector } from "../../redux/hooks"
import ScrollToTop from "../ScrollToTop/ScrollToTop.component"
import WithSpinner from "../WithSpinner/WithSpinner.component"
import BankAccountDashboardControlButtons from "./BankAccountDashboardControlButtons/BankAccountDashboardControlButtons.component"
import BankAccountDetailModal from "./BankAccountDetailModal/BankAccountDetailModal.component"
import BankAccountItems from "./BankAccountItems/BankAccountItems.component"
import BankAccountDashboardStyle from "./style"

const BankAccountItemsWithSpinner = WithSpinner(BankAccountItems)

const BankAccountDashboard = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(prevState => !prevState)

  //Select form fields values
  const selectFormFields = useMemo(() => selectFormFieldsValues(entityName), [entityName])
  const { data: formFields } = useAppSelector(selectFormFields)

  //Select proposal data to get contract permissions
  const selectProposalData = useMemo(() => selectContractProposalData({ contractId, entityName }), [
    contractId, 
    entityName
  ])
  const { data: proposalData } = useAppSelector(selectProposalData)

  const { 
    data: bankAccountItems, 
    isFetching: isFetchingBankItems, 
    isLoading: isLoadingBankItems,
  } = useFetchContractBankAccountItemsQuery({ contractId, entityName })

  return (
    <BankAccountDashboardStyle>
      <ScrollToTop />
      <header>
        Elenco dei metodi di pagamento
      </header>
      <BankAccountItemsWithSpinner
        isLoading={isFetchingBankItems || isLoadingBankItems}
        permissions={proposalData.links}
        bankAccountItems={bankAccountItems}
        bankAccountFieldsValues={formFields?.proposal ?? {}}
      />
      <BankAccountDashboardControlButtons 
        toggleBankAccountDetailModal={toggle}
        permissions={proposalData.links}
      />
      <BankAccountDetailModal
        isOpen={isOpen}
        toggle={toggle}
        permissions={proposalData.links}
        bankAccountFieldsValues={formFields?.proposal ?? {}}
      />
    </BankAccountDashboardStyle>
  )
}

export default BankAccountDashboard