import { useEffect, useMemo } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import ShowOnCondition from "../../../../auth/ShowOnCondition.component"

import InputWrapper from "../../../inputFields/Input/InputWrapper.component"

type PotentialityRowProps = {
  fieldName: string
  canEdit: boolean
  inputRequired?: boolean
  fieldLabel?: string
}

const PotentialityRow = ({
  fieldName,
  canEdit,
  inputRequired = false,
  fieldLabel,
}: PotentialityRowProps) => {
  const { register, control, setValue } = useFormContext()

  const noFieldName = useMemo(() => `${fieldName}No`, [fieldName])
  const potentialityFieldName = useMemo(() => `${fieldName}Potentiality`, [fieldName])

  const [ no, potentiality ] = useWatch({ 
    control, 
    name: [noFieldName, potentialityFieldName] 
  }) 

  //Update row total when no and potentiality fields are both valid numbers
  useEffect(() => {
    if(no && potentiality) {
      const rowTotal = no * potentiality
      setValue(`${fieldName}TotalPotentiality`, rowTotal)
    }
  }, [no, potentiality, setValue, fieldName])
  
  return (
    <tr>
      <td>
        <InputWrapper
          type="number"
          className="form-control"
          disabled={canEdit ? false : true}
          name={`${fieldName}No`}
          label="n."
          rules={{ 
            min: 0,
            maxLength: 20,
          }}
          register={register}
        />
      </td>
      <td>
        <ShowOnCondition showWhen={inputRequired}>
          <InputWrapper
            type="text"
            className="form-control"
            disabled={canEdit ? false : true}
            name={fieldName}
            label="Altro (specificare)"
            rules={{ maxLength: 80 }}
            register={register}
          />
        </ShowOnCondition>
        <ShowOnCondition showWhen={!inputRequired}>
          {fieldLabel}
        </ShowOnCondition>
      </td>
      <td>
        <InputWrapper
          type="number"
          className="form-control"
          disabled={canEdit ? false : true}
          name={`${fieldName}Potentiality`}
          label="kW"
          rules={{ 
            min: 0,
            maxLength: 20,
          }}
          register={register}
        />
      </td>
      <td>
        <InputWrapper
          type="number"
          readOnly
          className="form-control"
          name={`${fieldName}TotalPotentiality`}
          label="kW"
          register={register}
        />
      </td>
    </tr>
  )
}

export default PotentialityRow