import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"
import { contractApi } from "../../../../redux/contract/contract.api"
import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"

import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { asyncFnWrapper } from "../../utils/utils"
import { codiceFISCALE } from "../../utils/validations"

const User = ({
  canEdit,
}: FormProps) => {
  const { control, register, setValue, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  const [fetchPostalCodes] = contractApi.endpoints.fetchPostalCodes.useLazyQuerySubscription()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="id-card" className="me-2" />
        Utilizzatore
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={6}>
            <InputWrapper
              name="employeeName"
              label="Nome"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="employeeSurname"
              label="Cognome"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper 
              name="employeeVatNumber"
              label="Partita IVA"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                pattern: {
                  value: /^[0-9]{11}$/,
                  message: errorFormLabels.INVALID_VAT
                },
                maxLength: 11
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper 
              type="text"
              disabled={canEdit ? false : true}
              className="form-control text-uppercase"
              name="employeeFiscalCode"
              label="Codice fiscale"
              placeholder="Codice fiscale"
              rules={{
                required: errorFormLabels.REQUIRED,
                validate: value => codiceFISCALE(value) || errorFormLabels.INVALID_CF
              }}
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={10}>
            <InputWrapper
              name="employeeAddress"
              label="Indirizzo residenza"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 128,
                  message: maxLengthLabel(128)
                }
              }}
            />
          </Col>
          <Col md={2}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="employeeStreetNo"
              label="Civico"
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: 4,
              }}
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={8}>
            <AsyncSelectWrapper
              name="employeeTownName"
              control={control}
              register={register}
              label="Comune"
              errors={errors}
              isDisabled={canEdit ? false : true}
              loadOptions={(input: string) => {
                if(input.length > 2) {
                  return asyncFnWrapper(input, fetchTowns)
                }
              }}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => `${option.town} (${option.province})`}
              rules={{ required: errorFormLabels.REQUIRED }}
              whenFieldChanges={option => {
                setValue("employeePostalCode", null)
                setValue("employeeBirthProvince", option.province)
              }}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
          <Col md={2}>
            <InputWrapper
              name="employeeBirthProvince"
              label="Provincia"
              type="text"
              register={register}
              className="form-control"
              readOnly
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={2}>
            <SelectWrapper
              name="employeePostalCode"
              control={control}
              register={register}
              label="CAP"
              isDisabled={canEdit? false : true}
              errors={errors}
              rules={{ required: errorFormLabels.REQUIRED }}
              watchedFieldProps={{
                watch: watch,
                watchedFieldName: "employeeTownName",
                triggerWatchFn: fetchPostalCodes,
                returnedValuePropertyName: "town",
                defaultWatchedFieldValue: formWatcher.employeeTownName || null,
              }}
            />
          </Col>
          <Col md={10}>
            <InputWrapper
              name="employeeRegisteredOfficeAddress"
              label="Indirizzo sede legale"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 128,
                  message: maxLengthLabel(128)
                }
              }}
            />
          </Col>
          <Col md={2}>
            <InputWrapper
              type="text"
              className="form-control"
              disabled={canEdit ? false : true}
              name="employeeRegisteredOfficeStreetNo"
              label="Civico sede legale"
              rules={{ 
                maxLength: 4,
              }}
              register={register}
              errors={errors}
            />
          </Col>
          <Col md={8}>
            <AsyncSelectWrapper
              name="employeeRegisteredOfficeTownName"
              control={control}
              register={register}
              label="Comune sede legale"
              errors={errors}
              isDisabled={canEdit ? false : true}
              loadOptions={(input: string) => {
                if(input.length > 2) {
                  return asyncFnWrapper(input, fetchTowns)
                }
              }}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => `${option.town} (${option.province})`}
              whenFieldChanges={option => {
                setValue("employeeRegisteredOfficePostalCode", null)
                setValue("employeeRegisteredOfficeProvince", option.province)
              }}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
          <Col md={2}>
            <InputWrapper
              name="employeeRegisteredOfficeProvince"
              label="Provincia sede legale"
              type="text"
              register={register}
              className="form-control"
              readOnly
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={2}>
            <SelectWrapper
              name="employeeRegisteredOfficePostalCode"
              control={control}
              register={register}
              label="CAP sede legale"
              isDisabled={canEdit? false : true}
              errors={errors}
              watchedFieldProps={{
                watch: watch,
                watchedFieldName: "employeeRegisteredOfficeTownName",
                triggerWatchFn: fetchPostalCodes,
                returnedValuePropertyName: "town",
                defaultWatchedFieldValue: formWatcher.employeeRegisteredOfficeTownName || null,
              }}
            />
          </Col>


          <Col md={6}>
            <InputWrapper
              name="legalRepresentativeName"
              label="Nominativo rappresentante legale / procuratore"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="operationalRepresentativeName"
              label="Nominativo referente operativo"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              type="number"
              className="form-control"
              name="employeesNo"
              label="Numero di lavoratori"
              register={register}
              errors={errors}
              rules={{ 
                maxLength: 20,
                required: errorFormLabels.REQUIRED,
              }}
            />
          </Col>
          <Col md={6}>
            <InputWrapper
              name="probationPeriod"
              label="Periodo di prova"
              type="text"
              register={register}
              disabled={canEdit ? false : true}
              className="form-control"
              errors={errors}
              rules={{ 
                required: errorFormLabels.REQUIRED,
                maxLength: {
                  value: 80,
                  message: maxLengthLabel(80)
                }
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default User