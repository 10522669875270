import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { FieldValues, FormProvider, useFieldArray, useForm } from "react-hook-form"
import { Button, Form } from "reactstrap"
import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"

import { Permissions } from "../../../redux/api/types"
import { useCloseAttachmentsIntegrationMutation, useSubmitAttachmentMutation } from "../../../redux/contract/attachment/attachment.api"
import { AttachmentItemType } from "../../../redux/contract/contract.types"
import AttachmentsDropzone from "../../AttachmentsDropzone/AttachmentsDropzone.component"
import CanI from "../../auth/CanI.component"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import ScrollToTop from "../../ScrollToTop/ScrollToTop.component"
import AttachmentRow from "./AttachmentRow/AttachmentRow.component"
import AttachmentsIntegrationModal from "./AttachmentsIntegrationModal/AttachmentsIntegrationModal.component"
import { setUpSubmitValues } from "./utils"

type AttachmentsFormProps = {
  permissions: Permissions
  formFields: FieldValues
  attachmentsData?: AttachmentItemType[]
}

export type AttachmentsFormFieldValues = {
  dropzoneRow: AttachmentItemType[]
}

const AttachmentsForm = ({
  permissions,
  attachmentsData,
  formFields
}: AttachmentsFormProps) => {  
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const [attachmentsIntegrationIsOpen, setAttachmentsIntegrationIsOpen] = useState(false)
  const toggleAttachmentsIntegration = () => setAttachmentsIntegrationIsOpen(prevState => !prevState)
  const [submitAttachment] = useSubmitAttachmentMutation()
  const [submitCloseAttachmentsIntegration, {
    isLoading: isSubmittingCloseAttachments
  }] = useCloseAttachmentsIntegrationMutation()
  const formProps = useForm<AttachmentsFormFieldValues>({
    defaultValues: {
      dropzoneRow: attachmentsData ?? []
    }
  })
  const { control, handleSubmit, formState: { isSubmitting } } = formProps
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "dropzoneRow",
  })    

  const onSubmit = async (values: FieldValues) => {
    const attachments = setUpSubmitValues(values.dropzoneRow, contractId)
    await submitAttachment({ attachments, entityName })
  }

  return (
    <FormProvider {...formProps}>
      <ScrollToTop />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="file" className="me-2" />
            Aggiungi allegati al contratto
          </div>
          <div className="grouped-body">
            <CanI doWhat="CREATE" withPermissions={permissions} entityName={`${entityName}Attachment`}>
              {() => (
                <AttachmentsDropzone 
                  entityName={entityName}
                  addFile={prepend}
                />
              )}
            </CanI>
            <section className="attachments-list">
              {fields.map((field, index) => (
                <AttachmentRow 
                  key={field.id}
                  index={index}
                  field={field}
                  formFields={formFields}
                  entityName={entityName}
                  removeFile={remove}
                />
              ))}
            </section>
          </div>
        </div>
        <div className="text-end">
          <CanI doWhat="CREATE" withPermissions={permissions} entityName={`${entityName}Attachment`}>
            {() => (
              <ButtonWithLoader
                isLoading={isSubmitting}
                type="submit"
                disabled={isSubmitting}
                label="Carica allegati"
                fontAwesomeIcon={["fas", "upload"]}
              />
            )}
          </CanI>
          <CanI
            doWhat="CREATE"
            withPermissions={permissions}
            entityName={`${entityName}AttachmentsIntegrationClosure`}
          >
            {({ action }) => (
              <>
                <Button
                  disabled={isSubmitting}
                  className="ms-2"
                  color="warning"
                  data-testid="closure-btn"
                  onClick={toggleAttachmentsIntegration}
                >
                  <FontAwesomeIcon icon="square-check" className="me-2" />
                  <span>Concludi integrazione allegati</span>
                </Button>
                <AttachmentsIntegrationModal
                  action={action}
                  isOpen={attachmentsIntegrationIsOpen}
                  toggle={toggleAttachmentsIntegration}
                  isLoading={isSubmittingCloseAttachments}
                  onClick={async () => {
                    submitCloseAttachmentsIntegration({ 
                      actionUrl: action,  
                      values: {
                        contractLink: window.location.href
                      }
                    })
                  }}
                />
              </>
            )}
          </CanI>
        </div>
      </Form>
    </FormProvider>
  )
}

export default AttachmentsForm