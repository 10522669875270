import { useState } from "react"
import { FieldValues } from "react-hook-form"

import ScrollToTop from "../../ScrollToTop/ScrollToTop.component"
import OtpSignatureModal from "./OtpSignatureModal/OtpSignatureModal.component"
import OtpSigner from "./OtpSigner/OtpSigner.component"
import { Permissions } from "../../../redux/api/types"
import { useFetchOtpCodeMutation } from "../../../redux/contract/contract.api"
import { AttachmentItemType, OtpSignersType } from "../../../redux/contract/contract.types"
import { checkLastSigner } from "./utils"

type OtpFormProps = {
  otpSignersData: OtpSignersType
  contractId: string
  permissions: Permissions
  formFields?: FieldValues
  attachmentsData?: AttachmentItemType[]
  toggleFinalizationModal: () => void
}

const OtpForm = ({
  otpSignersData,
  toggleFinalizationModal,
  ...rest
}: OtpFormProps) => {
  //If a signer requests the OTP code, the other signers request buttons will be tempoararly disabled
  const [requestOtpCode, { isLoading }] = useFetchOtpCodeMutation()  
  const [otpSignatureIsOpen, setOtpSignatureIsOpen] = useState(false)
  const toggleOtpSignature = () => setOtpSignatureIsOpen(prevState => !prevState)

  return (
    <div className="my-2">
      <ScrollToTop />
      <h6 className="mb-4">Finalizza il contratto apponendo la firma con OTP</h6>
      {otpSignersData?.signers.map(item => 
        <div key={item.id}>
          <OtpSigner 
            signer={item} 
            toggleOtpSignatureModal={toggleOtpSignature}
            requestOtpCode={requestOtpCode}
            isSubmitting={isLoading}
          />
          <OtpSignatureModal 
            signer={item}
            isOpen={otpSignatureIsOpen}
            toggle={toggleOtpSignature}
            toggleFinalizationModal={toggleFinalizationModal}
            isLastSigner={checkLastSigner(otpSignersData.signers)}
          />
        </div>
      )}
    </div>
  )
}

export default OtpForm