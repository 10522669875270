import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PodPdrLegend = () => {
  return(
    <div className="legend">
      <ul>
        <li className="title">Legenda:</li>
        <li>
          <FontAwesomeIcon icon="signature" size="lg" />{' '}
          Inserito
        </li>
        <li>
          <FontAwesomeIcon icon="gears" size="lg" />{' '}
          In lavorazione
        </li>
        <li>
          <FontAwesomeIcon icon="circle-check" size="lg" />{' '}
          Validato
        </li>
        <li>
          <FontAwesomeIcon icon="hand" size="lg" />{' '}
          Bloccato
        </li>
        <li>
          <FontAwesomeIcon icon="arrows-rotate" size="lg" />{' '}
          Sincronizzato
        </li>
        <li>
          <FontAwesomeIcon icon="circle-xmark" size="lg" />{' '}
          K.O.
        </li>
      </ul>
    </div>
  )
}

export default PodPdrLegend
