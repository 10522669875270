import styled from 'styled-components'

const PageHeaderStyle = styled.header`
  padding: 1rem .25rem;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};

  .page-title {
    font-size: .85rem;
    margin: 0;
  }

  .lead {
    font-size: .85rem;
    margin: 0;

    strong {
      font-weight: 700;
    }
  }
`

export default PageHeaderStyle