import { useFetchAttachmentsQuery, useFetchContractPdrsQuery, useFetchContractPodsQuery } from "../../redux/contract/attachment/attachment.api"
import AttachmentsForm from "../forms/AttachmentsForm/AttachmentsForm.component"
import AttachmentsDashboardStyle from "./style"
import { useFetchContractProposalDataQuery, useFetchFormFieldsValuesQuery } from "../../redux/contract/contract.api"
import WithSpinner from "../WithSpinner/WithSpinner.component"
import { setUpContractPdpsOptions } from "./utils"
import { canI } from "../auth/utils"
import ShowOnCondition from "../auth/ShowOnCondition.component"
import AttachmentsIntegrationAlert from "./AttachmentsIntegrationAlert/AttachmentsIntegrationAlert.component"
import { useSelectContractId, useSelectEntityName } from "../../pages/providers/ContractsPageProvider.provider"

const AttachmentsFormWithSpinner = WithSpinner(AttachmentsForm)

const AttachmentsDashboard = () => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()

  const { data: formFields } = useFetchFormFieldsValuesQuery({ entityName })
  const { 
    data: proposalData, 
    isFetching: isFetchingProposal, 
    isLoading: isLoadingProposal,
  } = useFetchContractProposalDataQuery({ contractId, entityName })
  const fetchingProposalData = isFetchingProposal || isLoadingProposal

  //Fetch pods and pdrs to be shown in the attachment row select
  const {
    data: contractPods,
    isFetching: isFetchingContractPods,
    isLoading: isLoadingContractPods,
  } = useFetchContractPodsQuery({ entityName, contractId })
  const {
    data: contractPdrs,
    isFetching: isFetchingContractPdrs,
    isLoading: isLoadingContractPdrs,
  } = useFetchContractPdrsQuery({ entityName, contractId })
  const fetchingContractPdps = isFetchingContractPods || isLoadingContractPods || isFetchingContractPdrs || isLoadingContractPdrs

  const {
    data: attachmentsData,
    isFetching: isFetchingAttachmentsData,
    isLoading: isLoadingAttachmentsData,
  } = useFetchAttachmentsQuery({ entityName, contractId })
  const fetchingAttachmentsData = isFetchingAttachmentsData || isLoadingAttachmentsData

  const isLoading = fetchingProposalData || fetchingAttachmentsData || fetchingContractPdps

  //Check wether attachments integration alert should be shown
  const { abilityCheck: attachmentsIntegrationVisibility } = canI(
    `${entityName}AttachmentsIntegrationRequestCREATE`, 
    proposalData?.links
  )
  const attachmentsIntegrationHistory = proposalData?.attachmentsIntegrationHistory ?? []
  
  return (
    <AttachmentsDashboardStyle>
      <ShowOnCondition 
        showWhen={
          (
            attachmentsIntegrationVisibility || 
            !!attachmentsIntegrationHistory.length
          ) && !isLoading
        }
      >
        <AttachmentsIntegrationAlert 
          attachmentsIntegrationHistory={attachmentsIntegrationHistory}
          permissions={proposalData.links}
        />
      </ShowOnCondition>
      <AttachmentsFormWithSpinner
        isLoading={isLoading}
        permissions={proposalData?.links}
        formFields={Object.assign(
          {}, 
          formFields?.attachment, 
          { supplyPointId: setUpContractPdpsOptions(contractPods, contractPdrs, entityName) },
        ) || {}}
        attachmentsData={attachmentsData}
      />
    </AttachmentsDashboardStyle>
  )
}

export default AttachmentsDashboard