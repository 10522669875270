import { api } from "../../api"
import { ResponseType } from "../../api/types"
import { getAPIUrl } from "../../api/utils"
import { getEntityUriName } from "../contract.utils"

export const pdrApi = api.injectEndpoints({
  endpoints: build => ({
    fetchGasUseTypeId: build.query({
      query: ({ entityName, contractTypeId }) => ({
        url: `${getAPIUrl('abStorePortal')}/form-items/use-types?OpeningCausal=${entityName}&supplyPointType=gas&ContractType=${contractTypeId}`
      }),
      transformResponse: (response: ResponseType, meta, args) => response.data,
    }),
    fetchGasContractPriceList: build.query({
      query: ({ pdrId, userEmail, contractId, entityName, gasUseType, logicalPoint }) => ({
        url: `${getAPIUrl('abStorePortal')}/form-items/offers?FornitureType=G&pdrId=${pdrId || ''}&GasUseType=${gasUseType}&remiCode=${logicalPoint}&userEmail=${encodeURIComponent(userEmail)}&openingCausal=${entityName}&contractId=${contractId}`,
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchGasVendors: build.query({
      query: ({ entityName, searchedValue }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/pdrs/vendors?vendorName=${searchedValue}&limit=20`,
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchGasVatClassList: build.query({
      query: ({ customerType, entityName, gasUseType }) => ({
        url: `${getAPIUrl('abStorePortal')}/form-items/available-vats?customerType=${customerType}&openingCausal=${entityName}&useType=${gasUseType}&atecoCode=&supplyPointType=gas`
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchDistributors: build.query({
      query: ({ entityName, cadastralDataId, searchedValue }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/pdrs/distributors?cadastralDataId=${cadastralDataId}&distributorName=${searchedValue}&limit=20`,
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchRemiCodes: build.query({
      query: ({ entityName, cadastralDataId }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/pdrs/remi-codes/${cadastralDataId}`
      }),
      transformResponse: (response: { data: any }, meta, args) => response.data,
    }),
    fetchPdrData: build.query({
      query: ({ entityName, pdrId }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/pdrs/${pdrId}`
      }),
      providesTags: (response, error, arg) => [{ type: "Pdr", id: response?.id }],
    }),
    submitPdrData: build.mutation({
      query: ({ entityName, values }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/pdrs${values.id ? `/${values.id}` : ''}`,
        method: values.id ? "PUT" : "POST",
        body: values
      }),
      invalidatesTags: (response, error, args) => [
        { type: "Contract" },
        { type: "Pdr", id: "LIST" },
        { type: "CadastralData" as const, id: "LIST"},
        { type: "Pdr", id: response?.id },
      ],
    }),
    deletePdrItem: build.mutation({
      query: (actionUrl: string) => ({
        url: actionUrl,
        method: "DELETE"
      }),
      invalidatesTags: (response, error, args) => [
        { type: "Contract" },
        { type: "CadastralData", id: "LIST"},
        { type: "Pdr", id: "LIST" },
        { type: "List" },
      ],
    }),
    fetchPdrItemHistory: build.query({
      query: ({ entityName, pdpType, itemId }) => ({
        url: `${getAPIUrl()}/${getEntityUriName(entityName)}/${pdpType}s/${itemId}/logs`
      }),
      transformResponse: (response: { items: [] }, meta, args) => response.items,
    }),
    updatePdrItemStatus: build.mutation({
      query: ({ actionUrl, values }) => ({
        url: actionUrl,
        method: "PATCH",
        body: values
      }),
      invalidatesTags: (response, error, args) => [
        { type: "Contract" },
        { type: "CadastralData", id: "LIST"},
        { type: "Pdr", id: "LIST" },
        { type: "List" },
      ],
    })
  })
})

export const {
  useLazyFetchPdrDataQuery,
  useDeletePdrItemMutation,
  useLazyFetchPdrItemHistoryQuery,
  useUpdatePdrItemStatusMutation,
  useSubmitPdrDataMutation,
} = pdrApi

export const {
  endpoints: {
    fetchGasVendors,
    fetchDistributors,
    fetchRemiCodes,
    fetchGasUseTypeId,
    fetchGasContractPriceList,
    fetchGasVatClassList,
  }
} = pdrApi