import ShowOnCondition from "../../../components/auth/ShowOnCondition.component"
import PdrListContainer from "../../../components/lists/PdrList/PdrListContainer.component"
import PodListContainer from "../../../components/lists/PodList/PodListContainer.component"
import ProposalListContainer from "../../../components/lists/ProposalList/ProposalListContainer.component"
import { ListsTabsMap } from "../../../redux/contract/contract.utils"
import { useSelectSelectedTab } from "../../providers/ListsPageProvider.provider"

const GasAndPowerList = () => {
  const selectedTab = useSelectSelectedTab()

  return (
    <>      
      <ShowOnCondition showWhen={selectedTab === ListsTabsMap.PROPOSAL_TAB}>
        <ProposalListContainer />
      </ShowOnCondition>

      <ShowOnCondition showWhen={selectedTab === ListsTabsMap.POD_TAB}>
        <PodListContainer />
      </ShowOnCondition>

      <ShowOnCondition showWhen={selectedTab === ListsTabsMap.PDR_TAB}>
        <PdrListContainer />
      </ShowOnCondition>
    </>
  )
}

export default GasAndPowerList