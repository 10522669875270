import styled from "styled-components"

const CadastralDataDashboardStyle = styled.div`
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .title {
      font-size: .9rem;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.blue};
      margin: 0;
    }
  }

  .list-group-item {
    margin-bottom: 2.5rem;
    border-top: 1px solid #ddd;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin-bottom: 2.5rem;
    padding: 0;

    > header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0.75rem;

      .cadastral-info {
        font-size: .75rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.darkGrey};
      }

      .context-menu {
        display: flex;

        button {
          margin: 0 .25rem;
        }
      }
    }

    > main {
      background-color: ${({ theme }) => theme.colors.white};
      border-top: 1px solid ${({ theme }) => theme.colors.grey};
      padding: 0.75rem;
    }
  }
`

export default CadastralDataDashboardStyle