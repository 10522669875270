import styled from 'styled-components'

const LoginPageStyle = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  height: calc(100vh - 80px);

  .form-container {
    padding: 2rem 1rem;
    margin: 2.5rem;
    border: 3px solid #d8e4f1;
    border-radius: .75rem;
    width: 400px;
    background-color: #fafbfd;

    @media (max-width: 576px) {
      margin: 2.5rem 1rem;
    }
  }
`

export default LoginPageStyle