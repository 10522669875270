import styled from "styled-components"

const PdrItemStyle = styled.div`
  > header {
    display: block;
    font-size: .9rem;
    font-weight: 700;
    margin-bottom: .5rem;
    color: ${({ theme}) => theme.colors.blue};
  }

  .card-title {
    margin-bottom: .75rem;
    font-size: .9rem;
    font-weight: 500;
  }
`

export default PdrItemStyle