import styled from "styled-components"

const HistoryEventsListStyle = styled.div`
  .list-group-item {
    padding: .5rem;
    border-radius: .25rem;
  }

  .list-group-item p {
    font-size: .8rem;
  }

  .list-group-item strong {
    color: ${({ theme }) => theme.colors.blue};
  }

  .status-history {
    display: flex;
    align-items: center;

    strong {
      font-size: .85rem;
    }

    .status-icon,
    .icon {
      color: ${({ theme }) => theme.colors.blue} !important;
    }
  }
`

export default HistoryEventsListStyle