import { createDraftSafeSelector } from "@reduxjs/toolkit"

import { RootState } from "../store"
import { contractApi } from "./contract.api"
import { ContractStatusMap, ContractTabsMap, getFormStep } from "./contract.utils"

export const selectContract = (state: RootState) => state.contract

type selectContractProposalDataArgs = {
  contractId?: string
  entityName: string
}
export const selectContractProposalData = (args: selectContractProposalDataArgs) => 
  contractApi.endpoints.fetchContractProposalData.select(args)

export const selectFormFieldsValues = (entityName: string, formName?: string) => 
  contractApi.endpoints.fetchFormFieldsValues.select({ entityName, formName })

export const selectContractStatusId = (args: selectContractProposalDataArgs) => createDraftSafeSelector(
  [selectContractProposalData(args)],
  ({ data: proposalData }) => proposalData?.statusId ?? ContractStatusMap.ONGOING_STATUS
)

export const selectMaxFormStepAvailable = (args: selectContractProposalDataArgs) => createDraftSafeSelector(
  [selectContractProposalData(args)],
  ({ data: proposalData, originalArgs }) => {
    return proposalData ? (
      getFormStep(proposalData?.steps, originalArgs.entityName) 
    ) : (
      ContractTabsMap.PROPOSAL_STEP
    )
  }
)