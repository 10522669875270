import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FieldValues, useForm } from "react-hook-form"
import { Button, Form, FormGroup, Spinner } from "reactstrap"
import { useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { isActiveUserContract } from "../../../redux/contract/contract.utils"

import ShowOnCondition from "../../auth/ShowOnCondition.component"
import RadioWrapper from "../inputFields/Radio/RadioWrapper.component"

type PdpStatusUpdateFormProps = {
  currentPdpStatus: number
  actionUrl: string
  toggle: () => void
  updateFunction: Function
}

const PdpStatusUpdateForm = ({ 
  currentPdpStatus, 
  actionUrl,
  toggle,
  updateFunction,
}: PdpStatusUpdateFormProps) => {
  const entityName = useSelectEntityName()
  const { register, handleSubmit, formState: { isSubmitting } } = useForm({
    defaultValues: {
      status: currentPdpStatus.toString()
    }
  })

  const onSubmit = async(values: FieldValues) => {
    await updateFunction({ actionUrl, values })
    toggle()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <RadioWrapper 
          id="status-1"
          name="status"
          label="Inserito"
          className="form-check-input"
          register={register}
          value="1"
        />
      </FormGroup>
      <FormGroup>
        <RadioWrapper 
          id="status-2"
          name="status"
          label="In lavorazione"
          className="form-check-input"
          register={register}
          value="2"
        />
      </FormGroup>
      <FormGroup>
        <RadioWrapper 
          id="status-3"
          name="status"
          label="Validato"
          className="form-check-input"
          register={register}
          value="3"
        />
      </FormGroup>
      <FormGroup>
        <RadioWrapper 
          id="status-4"
          name="status"
          label="Bloccato"
          className="form-check-input"
          register={register}
          value="4"
        />
      </FormGroup>
      <ShowOnCondition showWhen={isActiveUserContract(entityName)}>
        <FormGroup>
          <RadioWrapper 
            id="status-5"
            name="status"
            label="Sincronizzato"
            className="form-check-input"
            register={register}
            value="5"
          />
        </FormGroup>
      </ShowOnCondition>
      <FormGroup>
        <RadioWrapper 
          id="status-6"
          name="status"
          label="K.O."
          className="form-check-input"
          register={register}
          value="6"
        />
      </FormGroup>

      <div className="text-end">
        <Button
          type="submit"
          disabled={isSubmitting}
          className="me-2"
        >
          {isSubmitting ? (
            <Spinner size="sm" color="light" className="me-2" />
          ) : (
            <FontAwesomeIcon icon="exchange-alt" className="me-2" />
          )}
          <span>Modifica</span>
        </Button>
        <Button onClick={toggle}>
          Annulla
        </Button>
      </div>
    </Form>
  )
}

export default PdpStatusUpdateForm