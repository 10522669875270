import ShowOnCondition from "../../../../auth/ShowOnCondition.component"
import ThTable from "../../../ThTable/ThTable.component"
import { useSelectEntityName } from "../../../../../pages/providers/ListsPageProvider.provider"
import { isActiveUserContract } from "../../../../../redux/contract/contract.utils"

type PodTHeaderProps = {
  sortParam: string
  setSortParam: (value: string) => void
  page: number
  filters: string
}

const PodTHeader = (props: PodTHeaderProps) => {
  const entityName = useSelectEntityName()

  return (
    <thead>
      <tr>
        <ThTable label="Status" />
        <ThTable 
          isSortable
          sortingName="proposalCode"
          label="Codice proposta"
          {...props}
        />
        <ShowOnCondition showWhen={isActiveUserContract(entityName)}>
          <ThTable 
            isSortable
            sortingName="customerCode"
            label="Codice cliente"
            {...props}
          />
        </ShowOnCondition>
        <ThTable label="Nome cliente" />
        <ThTable 
          isSortable
          sortingName="customerEmail"
          label="Email cliente"
          {...props}
        />
        <ThTable label="N° POD" />
        <ThTable 
          isSortable
          sortingName="agent"
          label="Nome agente"
          {...props}
        />
        <ThTable 
          isSortable
          sortingName="signingDate"
          label="Data firma"
          {...props}
        />
        <ThTable 
          isSortable
          sortingName="insertDate"
          label="Data caricamento"
          {...props}
        />
        <ThTable label="Offerta" />
        <ThTable />
      </tr>
    </thead>
  )
}

export default PodTHeader