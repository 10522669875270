import { Modal, ModalHeader, ModalBody, Row, Col, Form } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'

import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import { useOpenAttachmentsIntegrationMutation } from '../../../redux/contract/attachment/attachment.api'
import { errorFormLabels } from '../../forms/utils/formLabels'
import TextAreaWrapper from '../../forms/inputFields/TextArea/TextAreaWrapper.component'

type AttachmentsIntegrationModalProps = {
  actionUrl: string
  isOpen: boolean
  toggle: () => void
}

const AttachmentsIntegrationModal = ({ 
  actionUrl,
  isOpen, 
  toggle
}: AttachmentsIntegrationModalProps) => {
  const [openAttachmentsRequest] = useOpenAttachmentsIntegrationMutation()
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      contractLink: window.location.href,
      integrationCauses: null,
    }
  })  

  const onSubmit = async (values: any) => {
    await openAttachmentsRequest({ values, actionUrl })
    toggle()
  } 

  return (
    <Modal 
      className="form-modal"
      size="lg" 
      backdrop="static" 
      isOpen={isOpen} 
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="paperclip" className="me-2" />
        Integrazione allegati
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="info-circle" size="lg" className="me-2" />
              Inserisci le motivazioni della richiesta di integrazione
            </div>
            <div className="grouped-body">
              <Row>
                <Col md={12}>
                  <input type="hidden" {...register("contractLink")} />
                  <TextAreaWrapper 
                    name="integrationCauses"
                    label="Altro (specificare)"
                    register={register}
                    className="form-control"
                    errors={errors}
                    rules={{ 
                      required: errorFormLabels.REQUIRED,
                      maxLength: 512
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="text-end">
            <ButtonWithLoader 
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting}
              label="Invia"
              fontAwesomeIcon={["fas", "paper-plane"]}
            />
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AttachmentsIntegrationModal