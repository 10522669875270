import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { Form } from "reactstrap"

import { useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { selectCurrentUser } from "../../../redux/auth/auth.selectors"
import { BankAccountItemData, CadastralDataItemType } from "../../../redux/contract/contract.types"
import { useSubmitPdrDataMutation } from "../../../redux/contract/pdr/pdr.api"
import { useAppSelector } from "../../../redux/hooks"
import { canI } from "../../auth/utils"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import { useWhenFieldChangesHook } from "../PodForm/hooks/useWhenFieldChangesHook.hooks"
import EAttachment from "./EAttachment/EAttachment.component"
import FAttachment from "./FAttachment/FAttachment.component"
import GeneralData from "./GeneralData/GeneralData.component"
import OtherData from "./OtherData/OtherData.component"
import PdrOfferConfig from "./PdrOfferConfig/PdrOfferConfig.component"
import PdrSupply from "./PdrSupply/PdrSupply.component"
import Sales from "./Sales/Sales.component"
import { setDefaultFormData } from "./utils"

export type PdrFormProps = {
  customerType: Record<string, string>
  pdrFieldsValues: FieldValues
  pdrData?: any
  cadastralDetailData: CadastralDataItemType
  bankAccountData: BankAccountItemData[]
  togglePdpDetailModal: () => void
}

const PdrForm = ({
  pdrFieldsValues,
  customerType,
  cadastralDetailData,
  bankAccountData,
  pdrData,
  togglePdpDetailModal
}: PdrFormProps) => {
  const entityName = useSelectEntityName()
  const { email, role } = useAppSelector(selectCurrentUser)!  
  let canEdit = true
  if (pdrData) {
    canEdit = canI(`${entityName}PdrUPDATE`, pdrData.links).abilityCheck
  }
  const formProps = useForm<FieldValues>({
    defaultValues: {
      cadastralDataId: cadastralDetailData.id,
      bankAccountDetailId: bankAccountData[0].id,
      ...setDefaultFormData({ pdrFieldsValues, pdrData, customerType, entityName }),
    }
  })
  const { watch, setValue, handleSubmit, formState: { isSubmitting } } = formProps

  const [submitPdrData] = useSubmitPdrDataMutation()
  const onSubmit = async (values: FieldValues) => {
    await submitPdrData({ entityName, values })
    togglePdpDetailModal()
  }

  useWhenFieldChangesHook({
    watch,
    field: "gasUseTypeId",
    set: "contractPriceListCode",
    to: null,
    setValue
  })

  useWhenFieldChangesHook({
    watch,
    field: "gasUseTypeId",
    set: "vatClassId",
    to: null,
    setValue
  })

  useWhenFieldChangesHook({
    watch,
    field: "townName",
    set: "postalCode",
    to: null,
    setValue
  })

  useWhenFieldChangesHook({
    watch,
    field: "oldAccountHolderClientBusinessName",
    set: "oldAccountHolderPersonType",
    to: null,
    setValue
  })

  useWhenFieldChangesHook({
    watch,
    field: "contractType",
    set: "gasExciseClassId",
    to: null,
    setValue
  })

  return (
    <FormProvider {...formProps}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <GeneralData 
          canEdit={canEdit}
          fieldsValues={pdrFieldsValues}
          userRole={role}
          cadastralDataId={cadastralDetailData.id}
        />
        <PdrSupply 
          canEdit={canEdit}
          fieldsValues={pdrFieldsValues}
          customerType={customerType}
        />
        <PdrOfferConfig 
          canEdit={canEdit}
          fieldsValues={pdrFieldsValues}
          customerType={customerType}
          userEmail={email}
        />
        <FAttachment
          canEdit={canEdit}
          fieldsValues={pdrFieldsValues}
          customerType={customerType}
        />
        <EAttachment
          canEdit={canEdit}
          fieldsValues={pdrFieldsValues}
          customerType={customerType}
        />
        <OtherData 
          canEdit={canEdit}
          fieldsValues={pdrFieldsValues}
          customerType={customerType}
        />
        <Sales
          canEdit={canEdit}
          fieldsValues={pdrFieldsValues}
          customerType={customerType}
        />

        {canEdit &&
          <div className="text-end">
            <ButtonWithLoader 
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting}
              label="Salva"
              fontAwesomeIcon={["fas", "save"]}
            />
          </div>
        }
      </Form>
    </FormProvider>
  )
}

export default PdrForm