import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import MoneyInputWrapper from "../../inputFields/MoneyInput/MoneyInputWrapper.component"
import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"

const BillingData = ({ canEdit }: FormProps) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="money-bill" className="me-2" />
        Importo iniziale della retribuzione, elementi costitutivi, periodi e modalità di pagamento
      </div>
      <div className="grouped-body">
        <Row>
          <Col md={4}>
            <MoneyInputWrapper
              name="mothlyGrossPay"
              label="Retribuzione lorda mensile"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0.01,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="defaultPay"
              label="Retribuzione base"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0.01,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="contingency"
              label="Contingenza"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0.01,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="provinceElement"
              label="El. provinciale"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0.01,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="absorbableMin"
              label="Superminimo assorbibile"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0.01,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={4}>
            <MoneyInputWrapper
              name="totalMonthlyGrossPay"
              label="Retribuzione mensile lorda complessiva"
              type="number"
              step=".01"
              errors={errors}
              register={register}
              className="form-control"
              rules={{ 
                min: {
                  value: 0.01,
                  message: errorFormLabels.POSITIVE_NUMBER
                },
                maxLength: {
                  value: 20,
                  message: maxLengthLabel(20)
                },
              }}
              currency="€"
              disabled={canEdit ? false : true}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BillingData