import { FieldValues } from "react-hook-form"
import { setDefaultDate } from "../utils/utils"

type LanguageOption = {
  value: string
  label: string
  default: boolean
}

export function getDefaultOption(options: LanguageOption[]) {
  if(options) {
    const defaultOption = options.filter(opt => opt.default === true)
    return defaultOption.length ? defaultOption[0] : undefined
  }
}

export function relatedSwitchVisibility(entityName: string) {
  return entityName === 'transfer' ? true : false
}

export function electronicInvoiceRecipientCodeVisibility(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function extendedCompanyDataVisibility(entityName: string) {
  return (
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function singleInvoicePodVisibility(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function singleInvoicePdrVisibility(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function propertyTypeVisibility(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isMortisCausaVisibility(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function hasReconsiderationVisibility(entityName: string) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function privacyPolicyVisibility(entityName: string) {
  return (
    entityName === 'audaxChangeSupplier'
  ) ? false : true
}

type DefaultFormDataType = {
  proposalFieldsValues: FieldValues
  proposalData?: FieldValues
  defaultCustomerBirthCountry: Record<string, string>
}
export function setDefaultFormData({
  proposalFieldsValues, 
  proposalData,
  defaultCustomerBirthCountry,
}: DefaultFormDataType) {
  return proposalData ? ({
    ...proposalData,
    compilationDate: setDefaultDate(proposalData.compilationDate),
    signingDate: setDefaultDate(proposalData.signingDate),
    customerBirthDate: setDefaultDate(proposalData.customerBirthDate),
    identityBirthDate: setDefaultDate(proposalData.identityBirthDate),
    identityDocumentReleaseDate: setDefaultDate(proposalData.identityDocumentReleaseDate),
  }) : ({
    customerType: null,
    subscriptionTownName: null,
    compilationDate: new Date(),
    customerBirthCountry: defaultCustomerBirthCountry,
    identityDocumentType: null,
    customerSex: null,
    customerBirthDate: null,
    identityDocumentReleaseDate: null,
    identityDocumentEntity: null,
    singleInvoicePod: null,
    singleInvoicePdr: null,
    generalConditions: false,
    civilCode: false,
    privacy: false,
    profiling: undefined,
    thirdPartyCommunication: undefined,
    commercialCommunication: undefined,
    marketingIndirectCommunication: false,
    isMortisCausa: null,
    hasReconsideration: null,
    propertyType: null,
    hasPublicAdministration: null,
    hasSplitPayment: null,
    customerToponymId: null,
    customerTownName: null,
    customerPostalCode: null,
    identityBirthDate: null,
    identityBirthCountry: null,

  })
}