import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormContext } from "react-hook-form"
import { Col, Row } from "reactstrap"

import AsyncSelectWrapper from "../../inputFields/AsyncSelect/AsyncSelectWrapper.component"
import DatePickerWrapper from "../../inputFields/DatePicker/DatePickerWrapper.component"
import InputWrapper from "../../inputFields/Input/InputWrapper.component"
import SelectWrapper from "../../inputFields/Select/SelectWrapper.component"
import { contractApi } from "../../../../redux/contract/contract.api"
import { errorFormLabels, maxLengthLabel } from "../../utils/formLabels"
import { FormProps } from "../../utils/types"
import { asyncFnWrapper } from "../../utils/utils"
import { codiceFISCALE } from "../../utils/validations"

const User = ({
  canEdit,
  fieldsValues,
}: FormProps) => {
  const { register, control, setValue, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [fetchTowns] = contractApi.endpoints.fetchTowns.useLazyQuerySubscription()
  const [fetchPostalCodes] = contractApi.endpoints.fetchPostalCodes.useLazyQuerySubscription()

  return (
    <>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="id-card" className="me-2" />
          Dipendente
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <InputWrapper
                name="employeeName"
                label="Nome"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: {
                    value: 80,
                    message: maxLengthLabel(80)
                  }
                }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                name="employeeSurname"
                label="Cognome"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: {
                    value: 80,
                    message: maxLengthLabel(80)
                  }
                }}
              />
            </Col>
            <Col md={6}>
              <DatePickerWrapper
                name="employeeBirthDate"
                control={control}
                label="Data di nascita"
                className="form-control"
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                rules={{
                  required: errorFormLabels.REQUIRED,
                }}
                disabled={canEdit ? false : true}
              />
            </Col>
            <Col md={6}>
              <InputWrapper 
                type="text"
                disabled={canEdit ? false : true}
                className="form-control text-uppercase"
                name="employeeFiscalCode"
                label="Codice fiscale"
                placeholder="Codice fiscale"
                rules={{
                  required: errorFormLabels.REQUIRED,
                  validate: value => codiceFISCALE(value) || errorFormLabels.INVALID_CF
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={10}>
              <InputWrapper
                name="employeeAddress"
                label="Indirizzo residenza"
                type="text"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: {
                    value: 128,
                    message: maxLengthLabel(128)
                  }
                }}
              />
            </Col>
            <Col md={2}>
              <InputWrapper
                type="text"
                className="form-control"
                disabled={canEdit ? false : true}
                name="employeeStreetNo"
                label="Civico"
                rules={{ 
                  required: errorFormLabels.REQUIRED,
                  maxLength: 4,
                }}
                register={register}
                errors={errors}
              />
            </Col>
            <Col md={8}>
              <AsyncSelectWrapper
                name="employeeTownName"
                control={control}
                register={register}
                label="Comune"
                errors={errors}
                isDisabled={canEdit ? false : true}
                loadOptions={(input: string) => {
                  if(input.length > 2) {
                    return asyncFnWrapper(input, fetchTowns)
                  }
                }}
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => `${option.town} (${option.province})`}
                rules={{ required: errorFormLabels.REQUIRED }}
                whenFieldChanges={option => {
                  setValue("employeePostalCode", null)
                  setValue("employeeProvince", option.province)
                }}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
            <Col md={2}>
              <InputWrapper
                name="employeeProvince"
                label="Provincia"
                type="text"
                register={register}
                className="form-control"
                readOnly
                disabled={canEdit ? false : true}
              />
            </Col>
            <Col md={2}>
              <SelectWrapper
                name="employeePostalCode"
                control={control}
                register={register}
                label="CAP"
                isDisabled={canEdit? false : true}
                errors={errors}
                rules={{ required: errorFormLabels.REQUIRED }}
                watchedFieldProps={{
                  watch: watch,
                  watchedFieldName: "employeeTownName",
                  triggerWatchFn: fetchPostalCodes,
                  returnedValuePropertyName: "town",
                  defaultWatchedFieldValue: formWatcher.employeeTownName || null,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="briefcase" className="me-2" />
          Inquadramento
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                name="employeeRole"
                label="Qualifica"
                register={register}
                errors={errors}
                rules={{ 
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED,
                }}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                type="text"
                className="form-control"
                name="employeeCcnlLevel"
                label="Livello CCNL"
                register={register}
                errors={errors}
                rules={{ 
                  maxLength: 80,
                  required: errorFormLabels.REQUIRED,
                }}
              />
            </Col>
            <Col md={12}>
              <InputWrapper
                type="text"
                name="employeeDuties"
                label="Mansione"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED, 
                  maxLength: {
                    value: 80,
                    message: maxLengthLabel(80)
                  } 
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="calendar-days" className="me-2" />
          Data di inizio e periodo prova
        </div>
        <div className="grouped-body">
          <Row>
            <Col md={6}>
              <DatePickerWrapper
                name="startDate"
                control={control}
                label="Data inizio"
                className="form-control"
                errors={errors}
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                rules={{
                  required: errorFormLabels.REQUIRED,
                }}
                disabled={canEdit ? false : true}
              />
            </Col>
            <Col md={6}>
              <InputWrapper
                type="text"
                name="probationPeriod"
                label="Durata periodo di prova"
                register={register}
                disabled={canEdit ? false : true}
                className="form-control"
                errors={errors}
                rules={{ 
                  required: errorFormLabels.REQUIRED, 
                  maxLength: {
                    value: 80,
                    message: maxLengthLabel(80)
                  } 
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default User