import classNames from "classnames"
import { Table } from "reactstrap"
import PdrTBody from "./PdrTBody/PdrTBody.component"
import PdrTHeader from "./PdrTHeader/PdrTHeader.component"

type PdrTableProps = {
  sortParam: string
  setSortParam: (value: string) => void
  page: number
  filters: string
  isFetching: boolean
}

const PdrTable = ({
  isFetching,
  ...rest
}: PdrTableProps) => {
  return (
    <Table 
      responsive
      className={
        classNames({
          'is-fetching': isFetching === true
        })
      }
    >
      <PdrTHeader
        {...rest}
      />
      <PdrTBody
        {...rest}
      />
    </Table>
  )
}

export default PdrTable