import { FormProvider, FieldValues, useForm } from "react-hook-form"
import { Form } from "reactstrap"
import { useSelectContractId, useSelectEntityName } from "../../../pages/providers/ContractsPageProvider.provider"
import { selectCurrentUser } from "../../../redux/auth/auth.selectors"

import { BankAccountItemData, CadastralDataItemType } from "../../../redux/contract/contract.types"
import { useSubmitPodDataMutation } from "../../../redux/contract/pod/pod.api"
import { useAppSelector } from "../../../redux/hooks"
import ShowOnCondition from "../../auth/ShowOnCondition.component"
import { canI } from "../../auth/utils"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import { useWhenFieldChangesHook } from "./hooks/useWhenFieldChangesHook.hooks"
import PodFAttachment from "./PodFAttachment/PodFAttachment.component"
import PodGeneralData from "./PodGeneralData/PodGeneralData.component"
import PodOfferConfig from "./PodOfferConfig/PodOfferConfig.component"
import PodSupply from "./PodSupply/PodSupply.component"
import Promotions from "./Promotions/Promotions.component"
import RealState from "./RealState/RealEstate.component"
import Sales from "./Sales/Sales.component"
import { promotionsVisiblity, setDefaultFormData } from "./utils"

export type PodFormProps = {
  customerType: Record<string, string>
  podFieldsValues: FieldValues
  podData?: any
  cadastralDetailData: CadastralDataItemType
  bankAccountData: BankAccountItemData[]
  togglePdpDetailModal: () => void
}

const PodForm = ({
  podFieldsValues,
  customerType,
  cadastralDetailData,
  bankAccountData,
  podData,
  togglePdpDetailModal
}: PodFormProps) => {
  const entityName = useSelectEntityName()
  const contractId = useSelectContractId()
  const { email, role } = useAppSelector(selectCurrentUser)!  
  let canEdit = true
  if (podData) {
    canEdit = canI(`${entityName}PodUPDATE`, podData.links).abilityCheck
  }
  const formProps = useForm<FieldValues>({
    defaultValues: {
      cadastralDataId: cadastralDetailData.id,
      bankAccountDetailId: bankAccountData[0].id,
      ...setDefaultFormData({ podFieldsValues, podData, customerType, entityName }),
    }
  })
  const { watch, setValue, handleSubmit, formState: { isSubmitting } } = formProps

  const [submitPodData] = useSubmitPodDataMutation()
  const onSubmit = async (values: FieldValues) => {
    await submitPodData({ entityName, values })
    togglePdpDetailModal()
  }

  useWhenFieldChangesHook({ 
    watch,
    field: "town", 
    set: "distributor", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "electricUseTypeId", 
    set: "contractPriceListCode", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "electricUseTypeId", 
    set: "vatClassId", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "accountHolderAtecoCode", 
    set: "vatClassId", 
    to: null,
    setValue
  })
  useWhenFieldChangesHook({ 
    watch,
    field: "electricUseTypeId", 
    set: "electricExciseClassId", 
    to: null,
    setValue
  })

  return (
    <FormProvider {...formProps}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PodGeneralData 
          canEdit={canEdit}
          entityName={entityName}
          fieldsValues={podFieldsValues}
          userRole={role}
        />
        <PodSupply 
          canEdit={canEdit}
          entityName={entityName}
          fieldsValues={podFieldsValues}
          customerType={customerType}
        />
        <PodOfferConfig 
          canEdit={canEdit}
          entityName={entityName}
          fieldsValues={podFieldsValues}
          customerType={customerType}
          contractId={contractId}
          userEmail={email}
        />
        <PodFAttachment
          entityName={entityName}
          customerType={customerType}
          canEdit={canEdit}
          fieldsValues={podFieldsValues}
        />
        <RealState 
          canEdit={canEdit}
          entityName={entityName}
          fieldsValues={podFieldsValues}
          customerType={customerType}
          extendedPodDataNeeded={cadastralDetailData?.extendedPodDataNeeded}
        />
        <ShowOnCondition showWhen={promotionsVisiblity(entityName)}>
          <Promotions 
            canEdit={canEdit}
            entityName={entityName}
          />
        </ShowOnCondition>
        <Sales 
          canEdit={canEdit}
          fieldsValues={podFieldsValues}
          entityName={entityName}
        />

        {canEdit &&
          <div className="text-end">
            <ButtonWithLoader
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting}
              label="Salva"
              fontAwesomeIcon={["fas", "save"]}
            />
          </div>
        }
      </Form>
    </FormProvider>
  )
}

export default PodForm