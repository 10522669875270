export function isSupplyEqualToResidence(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}

export function isResidenceSelfDeclarationNeeded(entityName: string) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection' ||
    entityName === 'audaxChangeSupplier'
  ) ? true : false
}