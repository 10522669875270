import { useEffect, useState } from "react"
import { FieldValues, UseFormWatch } from "react-hook-form"

type UpdateAtecoCodeHookProps = {
  watch: UseFormWatch<FieldValues>
  defaultValue: string
}

export const useUpdateAtecoCodeHook = ({
  watch,
  defaultValue
}: UpdateAtecoCodeHookProps) => {
  const [atecoCode, setAtecoCode] = useState(defaultValue)

  const subscription = watch((data, { name, type }) => {
    if(name === "accountHolderAtecoCode") {
      setAtecoCode(data[name])
    }
  })
  useEffect(() => {
    return () => subscription.unsubscribe()
  }, [subscription])

  return atecoCode
}