export function isUseTypeNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}

export function isResidentNeeded(entityName: string) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}