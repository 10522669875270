import { Col, Row } from "reactstrap"

import { useSelectEntityName } from "../../../pages/providers/ListsPageProvider.provider"
import { ListDataType } from "../../../redux/list/list.types"
import DownloadExcelButton from "../../DownloadExcelButton/DownloadExcelButton.component"
import Pagination from "../Pagination/Pagination.component"
import { LIST_DEFAULT_ITEMS_PER_PAGE } from "../utils"

type TableFooterProps = {
  listData: ListDataType
  page: number
  setPage: (page: number) => void
  itemsPerPage: number
  buildExcelExportUrl?: (entityName: string, itemsPerPage: number) => string
  buildPdpExcelExportUrl?: (entityName: string, itemsPerPage: number) => string
}

const TableFooter = ({
  listData,
  page,
  setPage,
  buildExcelExportUrl,
  buildPdpExcelExportUrl
}: TableFooterProps) => {
  const entityName = useSelectEntityName()

  return (
    <>
      <Row className="align-items-center">
        <Col sm={9}>
          <Pagination
            totalItems={listData?.count ?? 0}
            page={page}
            setPage={setPage}
            itemsPerPage={LIST_DEFAULT_ITEMS_PER_PAGE}
          />      
        </Col>
        <Col sm={3}>
          <p className="total-items"><strong>Elementi totali:</strong> {listData?.count ?? 0}</p>
        </Col>
      </Row>
      {(buildExcelExportUrl || buildPdpExcelExportUrl) &&
        <div className="d-flex justify-content-end align-items-center my-2">
          {buildExcelExportUrl &&
            <DownloadExcelButton
              downloadLink={buildExcelExportUrl(entityName, LIST_DEFAULT_ITEMS_PER_PAGE)}
              label="Esporta excel"
            />
          } 
          {buildPdpExcelExportUrl &&
            <DownloadExcelButton
              downloadLink={buildPdpExcelExportUrl!(entityName, LIST_DEFAULT_ITEMS_PER_PAGE)}
              label="Esporta excel PDP"
              className="ms-2"
            />
          }
        </div>
      }
    </>
  )
}

export default TableFooter