import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { Button } from "reactstrap"

import RadioWrapper from "../../inputFields/Radio/RadioWrapper.component"
import PrivacyPolicyModal from "./PrivacyPolicyModal.component"

const PrivacyPolicy = () => {
  const { register, watch, formState: { errors } } = useFormContext()
  const formWatcher = watch()

  const [visibility, setVisibility] = useState(false)
  const toggle = () => setVisibility(prevState => !prevState)

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="bullhorn" className="me-2" />
        Consensi
      </div>
      <div className="grouped-body privacy">
        <div className="mb-3">
          Dichiaro di aver preso visione dell' 
          <Button color="link" className="link-button" onClick={toggle} size="sm">
            informativa sulla privacy
          </Button>{' '}.
          <PrivacyPolicyModal 
            isOpen={visibility}
            toggle={toggle}
          />
        </div>
        <div className="mb-3">
          <p className="mb-2">
            Inoltre,  in  merito  al  trattamento  dei  miei  dati  personali  per  l’invio  di  comunicazioni  
            commerciali  direttamente  da  parte di ABenergie  (marketing diretto – finalità di cui alla lettera e.)    
            e/o da parte di società partner di ABenergie (marketing indiretto – finalità di cui alla lettera f.)
          </p>
          <RadioWrapper 
            id="commercialCommunicationTrue"
            name="commercialCommunication"
            label="Presto il consenso"
            className="form-check-input"
            register={register}
            errors={errors}
            value={true}
            defaultChecked={formWatcher.commercialCommunication === true}
          />
          <RadioWrapper 
            id="commercialCommunicationFalse"
            name="commercialCommunication"
            label="Nego il consenso"
            register={register}
            errors={errors}
            className="form-check-input"
            value={false}
            defaultChecked={formWatcher.commercialCommunication === false}
          />
        </div>
        <div className="mb-3">
          <p className="mb-2">
            Inoltre, in merito al trattamento dei miei dati personali per finalità di profilazione generica (profilazione – finalità di cui alla lettera g.) 
          </p>
          <RadioWrapper 
            id="profilingTrue"
            name="profiling"
            label="Presto il consenso"
            className="form-check-input"
            register={register}
            errors={errors}
            value={true}
            defaultChecked={formWatcher.profiling === true}
          />
          <RadioWrapper 
            id="profilingFalse"
            name="profiling"
            label="Nego il consenso"
            register={register}
            className="form-check-input"
            errors={errors}
            value={false}
            defaultChecked={formWatcher.profiling === false}
          />
        </div>
        <div className="mb-3">
          <p className="mb-2">
            Inoltre,  in  merito  al  trattamento  dei  miei  dati  personali  per  la  comunicazione  a  società  partner  di  ABenergie  per  l’invio di loro comunicazioni commerciali (cessione di dati a terzi – finalità di cui alla lettera h.)
          </p>
          <RadioWrapper 
            id="thirdPartyCommunicationTrue"
            name="thirdPartyCommunication"
            label="Presto il consenso"
            className="form-check-input"
            register={register}
            errors={errors}
            value={true}
            defaultChecked={formWatcher.thirdPartyCommunication === true}
          />
          <RadioWrapper 
            id="thirdPartyCommunicationFalse"
            name="thirdPartyCommunication"
            label="Nego il consenso"
            register={register}
            errors={errors}
            className="form-check-input"
            value={false}
            defaultChecked={formWatcher.thirdPartyCommunication === false}
          />
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy