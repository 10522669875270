import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import LandingPage from '../../pages/LandingPage/LandingPage.component'
import LoginPage from '../../pages/LoginPage/LoginPage.component'
import WithSpinner from '../WithSpinner/WithSpinner.component'
import PrivateRoute from './PrivateRoute.component'
import ContractsFormPage from '../../pages/ContractsFormPage/ContractsFormPage.component'
import ContractsListPage from '../../pages/ContractsListPage/ContractsListPage.component'
import UserPage from '../../pages/UserPage/UserPage.component'
import UserListPage from '../../pages/UserListPage/UserListPage.component'
import UserMassiveCreationPage from '../../pages/UserMassiveCreationPage/UserMassiveCreationPage.component'
import FirebaseAuthPage from '../../pages/FirebaseAuthPage/FirebaseAuthPage.component'
import NoMatchPage from '../../pages/NoMatchPage/NoMatchPage.component'
import { selectCurrentUser, selectIsCheckingUserSession } from '../../redux/auth/auth.selectors'

const ContractsFormPageContainer = WithSpinner(ContractsFormPage)
const ContractsListPageContainer = WithSpinner(ContractsListPage)
const UserListPageContainer = WithSpinner(UserListPage)
const UserPageContainer = WithSpinner(UserPage)
const UserMassiveCreationPageContainer = WithSpinner(UserMassiveCreationPage)

const AppRouter = () => {
  const userLoggedIn = useSelector(selectCurrentUser) ? true : false
  const isLoading = useSelector(selectIsCheckingUserSession)

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              {userLoggedIn ? (
                <LandingPage />
              ) : (
                <LoginPage />
              )}
            </>
          }
        />

        {/*Gestione utenti*/}
        <Route
          path="/utenti/all"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <UserListPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path="/utenti/creazione-massiva"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <UserMassiveCreationPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path="utenti/:userId"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <UserPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path="utenti"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <UserPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />

        {/* Contracts list */}
        <Route
          path=":pathEntityName/all"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <ContractsListPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />

        {/* Contract form page */}
        <Route 
          path=":pathEntityName/:contractId" 
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <ContractsFormPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
        <Route
          path=":pathEntityName"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <ContractsFormPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />

        <Route
          path={"/firebase-auth/*"}
          element={<FirebaseAuthPage />}
        />

        <Route
          path="*"
          element={<NoMatchPage />} 
        />

      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter