import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { useState } from "react"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

import { useSelectEntityName } from "../../../../../../pages/providers/ListsPageProvider.provider"
import { selectCurrentUserRole } from "../../../../../../redux/auth/auth.selectors"
import { UserRoles } from "../../../../../../redux/auth/auth.utils"
import { useDeleteProposalDataMutation } from "../../../../../../redux/contract/contract.api"
import { getEntityPath, getSupplyPointStatusIcon, isActiveUserContract } from "../../../../../../redux/contract/contract.utils"
import { useLazyFetchPdrItemHistoryQuery } from "../../../../../../redux/contract/pdr/pdr.api"
import { useAppSelector } from "../../../../../../redux/hooks"
import { PdrItemType } from "../../../../../../redux/list/list.types"
import { isThisItemLoading } from "../../../../../../utils"
import CanI from "../../../../../auth/CanI.component"
import ShowOnCondition from "../../../../../auth/ShowOnCondition.component"
import { canI } from "../../../../../auth/utils"
import ButtonWithLoader from "../../../../../ButtonWithLoader/ButtonWithLoader.component"
import DeleteItem from "../../../../../DeleteItemModal/DeleteItemModal.component"
import PdpStatusHistoryModal from "../../../../../PdpStatusHistoryModal/PdpStatusHistoryModal.component"
import PdpStatusUpdateModal from "../../../../../PdpStatusUpdateModal/PdpStatusUpdateModal.component"
import TdTable from "../../../../TdTable/TdTable.component"

type PdrTRowProps = {
  item: PdrItemType
  sortParam: string
}

const PdrTRow = ({
  item,
  sortParam,
}: PdrTRowProps) => {
  const entityName = useSelectEntityName()
  const role = useAppSelector(selectCurrentUserRole)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const toggleDelete = () => setDeleteIsOpen(prevState => !prevState)
  const [isOpenStatusHistory, setIsOpenStatusHistory] = useState(false)
  const toggleStatusHistory = () => setIsOpenStatusHistory(prevState => !prevState)
  const [isOpenUpdateStatus, setIsOpenUpdateStatus] = useState(false)
  const toggleUpdateStatus = () => setIsOpenUpdateStatus(prevState => !prevState)

  const [fetchPdrItemHistoryData, {
    data: pdrHistoryData,
    isLoading: isLoadingPdrHistoryData,
    isFetching: isFetchingPdrHistoryData,
  }, { 
    lastArg: { itemId: lastPdrHistoryLoadedItemId },
  }] = useLazyFetchPdrItemHistoryQuery()
  const fetchingHistoryData = isLoadingPdrHistoryData || isFetchingPdrHistoryData

  const [deleteItem, { isLoading: isDeletingItem }] = useDeleteProposalDataMutation()

  return (
    <tr>
      <TdTable
        sortParam={sortParam}
        sortingName="statusId"
        otherClasses={['text-center', 'status']}
      >
        <FontAwesomeIcon
          size="lg" 
          className="mr-2" 
          icon={getSupplyPointStatusIcon(item.supplyPointStatus)} 
          title={item.statusName}
        />
      </TdTable>
      <TdTable 
        value={item.proposalCode}
        sortParam={sortParam}
        sortingName="proposalCode"
      />
      <ShowOnCondition showWhen={isActiveUserContract(entityName)}>
        <TdTable 
          value={item.customerCode}
          sortParam={sortParam}
          sortingName="customerCode"
        />
      </ShowOnCondition>
      <TdTable value={item.customerName} />
      <TdTable 
        value={item.customerEmail}
        sortParam={sortParam}
        sortingName="customerEmail"
        otherClasses={['break-word']}
      />
      <TdTable value={item.no} />
      <TdTable value={item.userAgentName} />
      <TdTable 
        value={item.signingDate ? moment(item.signingDate).format('DD-MM-YYYY') : ""}
        sortParam={sortParam}
        sortingName="signingDate"
      />
      <TdTable 
        value={item.insertDate ? moment(item.insertDate).format('DD-MM-YYYY') : ""}
        sortParam={sortParam}
        sortingName="insertDate"
      />
      <TdTable 
        value={item.contractPriceListCode}
        sortParam={sortParam}
        sortingName="offer"
      />
      <TdTable otherClasses={["actions-col"]}>
        <div className="buttons-container">
          <ShowOnCondition
            showWhen={
              (role === UserRoles.USER_ROLE_ADMIN || role === UserRoles.USER_ROLE_VERIFIER) &&
              canI(`${entityName}PdrGET`, item.links).abilityCheck
            }
          >
            <ButtonWithLoader 
              fontAwesomeIcon={["fas", "history"]}
              size="sm"
              color="secondary"
              outline
              isLoading={isThisItemLoading({ 
                itemId: item.id, 
                lastLoadedItemId: lastPdrHistoryLoadedItemId, 
                otherLoadingChecks: fetchingHistoryData 
              })}
              onClick={async() => {
                await fetchPdrItemHistoryData({ entityName, pdpType: "pdr", itemId: item.id })
                toggleStatusHistory()
              }}
            />
            <PdpStatusHistoryModal
              isOpen={isOpenStatusHistory}
              toggle={toggleStatusHistory}
              itemHistoryData={pdrHistoryData!}
            />
          </ShowOnCondition>
          <CanI 
            doWhat="PATCH" 
            withPermissions={item.links} 
            entityName={`${entityName}Pdr`}
          >
            {({ action }) => (
              <div>
                <Button
                  outline 
                  size="sm" 
                  color="secondary"
                  onClick={toggleUpdateStatus}
                >
                  <FontAwesomeIcon icon="exchange-alt" />
                </Button>
                <PdpStatusUpdateModal
                  isOpen={isOpenUpdateStatus}
                  toggle={toggleUpdateStatus}
                  currentPdpStatus={item.supplyPointStatus}
                  actionUrl={action}
                  pdpType="pdr"
                />
              </div>
            )}
          </CanI>
          <ShowOnCondition showWhen={canI(`${entityName}PdrGET`, item.links).abilityCheck}>
            <Link
              target="_blank"
              title="Vedi dettaglio"
              className="btn btn-outline-secondary btn-sm"
              to={`/${getEntityPath(entityName)}/${item.contractId}/`}
            >
              <FontAwesomeIcon size="sm" icon="eye" />
            </Link>
          </ShowOnCondition>
          <CanI doWhat={"DELETE"} withPermissions={item.links} entityName={`${entityName}Pdr`}>
            {({ action }) => (
              <>
                <ButtonWithLoader
                  size="sm"
                  fontAwesomeIcon={['fas', 'trash-alt']}
                  isLoading={isDeletingItem}
                  outline
                  onClick={toggleDelete}
                  color="danger"
                />
                <DeleteItem
                  isOpen={deleteIsOpen}
                  toggle={toggleDelete}
                  deleteFn={async () => {
                    await deleteItem(action)
                    toggleDelete()
                  }}
                  isDeleting={isDeletingItem}
                />
              </>
            )}
          </CanI>
        </div>
      </TdTable>
    </tr>
  )
}

export default PdrTRow